import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Card } from '@tremor/react';

import useClientStore from '../../../store/useClientStore';
import Tooltip from '../../HelpTooltip';
import ToolTipV2 from '../../TooltipV2';
import Icon from '../../../assets/icons/SvgComponent';
import DynamicPage from './DynamicPage';
import useDashboardStore from '../../../store/useDashboardStore';
import EmptyPage from '../EmptyPage';
import { useEffect, useMemo, useState } from 'react';
import CustomModal from '../../CustomModal';
import ResetTemplateConfirmation from './resetTemplateConfirmation';

const ClientDashboard = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const { selectedClient, fetchClient } = useClientStore((state) => state);

  const searchParams = new URLSearchParams(location.search);
  
  const clientId = searchParams.get('clientId');
  const viewType = searchParams.get('viewType');
  const tab = searchParams.get('tab');

  const currentTab = useMemo(() => {
    let currTab = 'seo'
    if(tab === "paid-search") {
      currTab = 'google_ads'
    }
    if(tab === "microsoft-ads") {
      currTab = 'microsoft_ads'
    }
    if(tab === "fb-ads") {
      currTab = 'meta'
    }
    return currTab
  },[tab])

  const [isConfirmModalResetTemplate, setConfirmModalResetTemplate] = useState(false);
  const [isConfirmModalResetTemplateSubmitted, setIsConfirmModalResetTemplateSubmitted] = useState(false);

  const {
    sections,
    fetchDashboardTemplate,
    isSectionsFetched,
    addNewSection,
    isAddingNewSection,
    dashboard_template_id,
    deleteContent,
    serviceLine
  } = useDashboardStore((state) => state);

  useEffect(() => {
    if (!isSectionsFetched && sections?.length === 0) {
      fetchPages();
    }
  }, [selectedClient]);

  useEffect(() => {
    if(!selectedClient?.id) {
      fetchClient(clientId);
    }
  },[selectedClient]);

  const fetchPages = () => {
    if (selectedClient?.id || clientId) fetchDashboardTemplate(clientId ? clientId : selectedClient?.id, serviceLine);
  };

  const deleteIntegration = async () => {
    setIsConfirmModalResetTemplateSubmitted(true)
    const action: any = await deleteContent(clientId ? clientId : selectedClient?.id);

    if(action.isSucess) {
      setConfirmModalResetTemplate(false);
      navigate("/client/analytics-grid/seo-ga4")
    }
    
    setIsConfirmModalResetTemplateSubmitted(false)
  }
  // console.log(dashboard_template_id,'dashboard_template_id')
  return (
    <>
    <div>
      <div className="bg-white p-6 m-4 rounded-md">
        <div className={`relative block lg:flex items-start justify-between`}>
          <div className="flex gap-2 items-center sm:block w-full md:w-[16vw] lg:w-[30vw] my-1 sm:my-0">
            <div className="flex justify-between items-center sm:justify-start sm:gap-2 text-[26px] sm:text-3xl  font-[800] text-[#001C44] dark:text-white">
              <div>
                <Tooltip content={selectedClient?.name} position="right">
                  <div className="max-w-[80vw] sm:max-w-[65vw] md:max-w-[15vw] lg:max-w-[35vw] truncate cursor-pointer  text-[24px]">
                    {selectedClient?.name}
                  </div>
                </Tooltip>
              </div>
              <div className="hidden sm:block">
                <ToolTipV2 tooltip="Edit Name">
                  <Icon name="Edit" size={18} color="#0029FF" />
                </ToolTipV2>
              </div>
            </div>

            <div className="flex gap-2">
              {selectedClient?.loading && (
                <div className="bg-[#B2BBC740] w-[120px] h-[24px]"></div>
              )}
              {selectedClient?.category_type && (
                <div className="uppercase text-xs font-normal bg-[#B2BBC740] py-1 px-2 rounded-md w-fit h-fit sm:h-full">
                  {selectedClient?.category_type}
                </div>
              )}
              {selectedClient?.cycle &&
                !location.pathname.includes('/client/analytics/paid-search') &&
                !location.pathname.includes('/client/analytics/fb-ads') && (
                  <div className=" text-xs text-white rounded-md py-1 px-2 bg-[#009EFF] w-fit h-fit sm:h-full">
                    {selectedClient?.cycle}
                  </div>
                )}
            </div>
          </div>
          <div className="bg-white  rounded-md">
            <div className="flex gap-2">
              <button
                onClick={() => setConfirmModalResetTemplate(true)}
                className="min-w-[162px] font-semibold hidden sm:flex font-inter bg-red-700 hover:bg-red-800 text-sm  items-center justify-center gap-1 rounded-lg py-1.5 px-2 text-cyan-50 cursor-pointer">
                Reset Template
              </button>
              {/* <Link
                to={`/client/analytics-grid/dashboard/preview?clientId=${selectedClient?.id}`}
                target='_blank'
                className="min-w-[103px] font-semibold flex font-inter border border-[#0029FF] text-[#0029FF] text-sm  items-center justify-center gap-1 rounded-lg py-1.5 px-2 cursor-pointer mr-2">
                Preview
              </Link> */}
              <Link
                className="min-w-[103px] font-semibold flex font-inter bg-[#0029FF] text-sm  items-center justify-center gap-1 rounded-lg py-1.5 px-2 text-cyan-50 cursor-pointer"
                to={`/client/analytics-grid/${tab}`}
              >
                Save
              </Link>
            </div>
          </div>
        </div>
        <hr className="my-8" />

        {sections &&
          !sections[0]?.error &&
          sections.length > 0 &&
          sections
            .sort((a, b) => a.order - b.order)
            .map((section) => (
            <Card
              className="dark:bg-[#222732] px-2 sm:px-6 mt-0 sm:mt-2"
              key={section.id}
            >
              <DynamicPage
                key={section._id}
                dashboardPageId={section._id}
                title={section.title}
                order={section.order}
                dashboard={section?.contents[0]}
                viewType="edit"
                serviceLine={currentTab}
              />
            </Card>
          ))}

        {/* {isAddingNewSection && (
          <div className="mt-8">
            <DynamicPage
              dashboardPageId={null}
              title="New Section"
              order={sections?.length + 1}
              viewType="edit"
            />
          </div>
        )} */}

        <div className="relative w-full border-t my-12">
          <div className="absolute left-[45%] -top-[40px] bg-white px-4">
            <button
              onClick={() => addNewSection(true)}
              className="mt-4 text-center text-[14px] font-[500] text-[#0029FF] py-[10px] px-[16px] border rounded-md"
            >
              Add Section
            </button>
          </div>
        </div>
      </div>
    </div>
    <CustomModal
        open={isConfirmModalResetTemplate}
        onClose={() => setConfirmModalResetTemplate(false)}
      >
        <ResetTemplateConfirmation
          onClose={() => setConfirmModalResetTemplate(false)}
          isSubmitted={isConfirmModalResetTemplateSubmitted}
          resetLabel={isConfirmModalResetTemplateSubmitted ? "Resetting template..." : "Reset"}
          onDelete={() => {
            if (dashboard_template_id) {
              deleteIntegration()
            }
          }}
        />
    </CustomModal>
    </>
  );
};

export default ClientDashboard;
