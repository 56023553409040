import { requests } from "./ApiService";

export const Tools = {
  getPositions: (filters) =>
    requests.get(
      `/api/v1/tools/positions?domain=${filters.domain}&database=${filters.database}&excluded=${filters.excluded}`
    ),
  putContacts: (filters) =>
    requests.post(
      `/api/v1/tools/contact_finder?keyword=${filters.keyword}&database=${filters.database}&email=${filters.userEmail}&force=${filters.force}&industry=${filters.industry}`
    ),
  getContacts: (filters) =>
    requests.get(
      `/api/v1/tools/contact_finder?report_id=${filters.report_id}`
    ),
  getKeywordCompetitors: (filters) =>
    requests.get(
      `/api/v1/tools/keyword_competitors?domain=${filters.domain}&database=${filters.database}&primaryKeyword=${filters.primaryKeyword}&position=${filters.position}`
    ),
  getQuotas: () =>
    requests.get(
      `/api/v1/tools/quotas`
    ),
};
