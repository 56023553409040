import { useEffect, useState } from 'react';
import moment from 'moment';
import DatePicker from 'react-datepicker';

import {
  Tab,
  TabGroup,
  TabList,
  TabPanel,
  TabPanels,
} from '@tremor/react';

import Icon from '../../assets/icons/SvgComponent';
import ReactSelect from '../ReactSelect';
import {
  getMetricSourceOptions,
  getMetricsTooltip,
  getNearestSunday,
  getStatusMetric,
  intervals,
  metricSourceOptions,
  metricSourceOptionsPerService
} from './constants';

import 'react-datepicker/dist/react-datepicker.css';

interface IProps {
  selectedItem?: any;
  handleSaveItem: (data: any) => void;
  handleDeleteItem: (data: any) => void;
  onClose?: any;
  serviceLine?: string;
}

const WidgetSettings = ({
  selectedItem,
  handleSaveItem,
  handleDeleteItem,
  onClose,
  serviceLine="seo"
}: IProps) => {
  
  const [isDisabled, setIsDisabled] = useState(false);
  const [error, setError] = useState({
    status: false,
    message: '',
  });
  const today = new Date();
  const fDateAddMonth = moment().add(13, "months");
  const [title, setTitle] = useState('');
  const [chartType, setChartType] = useState('');
  const [property, setProperty] = useState('');
  const [metrics, setMetrics] = useState('');
  const [trendLine, setTrendLine] = useState(true);
  const [integrationIcon, setIntegrationIcon] = useState(true);
  const [dataLabels, setDataLabels] = useState(true);
  const [themeStyle, setThemeStyle] = useState('');
  const [themeColor, setThemeColor] = useState('');
  
  const [interval, setInterval] = useState<{
      label: string;
      value: string;
    }>({
      label: 'Select Interval',
      value: '',
    });
  const [customStartDate, setCustomStartDate] = useState<any>(today);
  const [customEndDate, setCustomEndDate] = useState<any>(moment(today).add(7, 'days'));

  const [selectedSourceMetric, setSelectedSourceMetric] = useState<{
    label: string;
    value: string;
  }>({
    label: 'Select Source',
    value: '',
  });
  const [selectedMetric, setSelectedMetric] = useState<{
    label: string;
    value: string;
  }>({
    label: 'Select Metric',
    value: '',
  });

  useEffect(() => {
    if (selectedItem) {
      console.log(selectedItem, 'selectedItem');
      // source
      setSelectedSourceMetric({
        label: metricSourceOptions.filter(
          (s) => s.value === selectedItem?.data_config?.source
        )[0]?.label,
        value: selectedItem?.data_config?.source,
      });
      setSelectedMetric({
        label: getStatusMetric(selectedItem?.data_config?.metrics),
        value: selectedItem?.data_config?.metrics,
      });

      setTitle(selectedItem?.title);
      // setToolTip(selectedItem?.toolTip);

      // if(!selectedSourceMetric.value && !selectedMetric.value) {
      //   setIsDisabled(true)
      // } else {
      setIsDisabled(false);
      // }
      setProperty(selectedItem?.data_config?.property)
      setMetrics(selectedItem?.data_config?.metrics);

      setChartType(selectedItem?.display_config?.chartType);

      setTrendLine(selectedItem?.display_config?.showTrendline);
      setIntegrationIcon(selectedItem?.display_config?.showIntegrationIcon);
      setDataLabels(selectedItem?.display_config?.showDataLabels);

      setThemeStyle(selectedItem?.display_config?.themeStyle);
      setThemeColor(selectedItem?.display_config?.themeColor);

      // dates
      setInterval({
        value: selectedItem?.display_config?.interval || 'monthly',
        label: intervals?.find( i => i.value === selectedItem?.display_config?.interval)?.label || 'Monthly'
      });
      setCustomStartDate(selectedItem?.display_config?.customStartDate ? new Date(selectedItem?.display_config?.customStartDate) : today);
      setCustomEndDate(selectedItem?.display_config?.customEndDate 
        ? new Date(selectedItem?.display_config?.customEndDate) 
        : moment(fDateAddMonth).endOf('month').format('YYYY-MM-DD')
      )
    }
  }, [selectedItem]);

  const handleSave = () => {
    if (!selectedSourceMetric?.value && !selectedMetric?.value) {
      setError({
        status: true,
        message: 'Metrics and Source are required.',
      });
      return false;
    }
    if(!title) {
      setError({
        status: true,
        message: 'Title is required.',
      });
      return false;
    }
    if(!chartType) {
      setError({
        status: true,
        message: 'Display type is required.',
      });
      return false;
    }

    
    const dates = {
      interval: interval?.value || 'monthly',
      customStartDate: interval?.value ? moment(customStartDate).format('YYYY-MM-DD') : moment(today).startOf('month').format('YYYY-MM-DD'),
      customEndDate: interval?.value ? moment(customEndDate).format('YYYY-MM-DD') : moment(fDateAddMonth).endOf('month').format('YYYY-MM-DD'),
    }
    
    handleSaveItem({
      id: selectedItem?.id,
      key: selectedItem?.key,
      title,
      toolTip: getMetricsTooltip(selectedSourceMetric?.value,selectedMetric?.value),
      data_config: {
        property, // error on update - remove soon
        source: selectedSourceMetric?.value,
        metrics: selectedMetric?.value,
      },
      display_config: {
        ...dates,
        chartType,
        range: 'lastYear',
        showTrendline: trendLine,
        showIntegrationIcon: integrationIcon,
        showDataLabels: dataLabels,
        themeColor: themeColor,
        themeStyle: themeStyle,
      },
    });
  };

  const handleDelete = () => {
    handleDeleteItem(selectedItem);
  };

  return (
    <div className="h-screen w-screen flex justify-end">
      <div
        className="bg-[#fff] dark:bg-gray-900 relative w-[554px] p-4 sm:p-6  text-[#001C44] flex flex-col justify-start"
        onClick={(e) => e.stopPropagation()}
      >
        <div className="flex justify-between items-center border-b pb-3">
          <h1 className="text-base font-[700] dark:text-white flex flex-row items-center">
            <span onClick={onClose} className="inline-block cursor-pointer">
              <Icon name="arrowLeft" color="#0029FF" />
            </span>
            {` `}&nbsp; Edit Widget
          </h1>
          <div className="block sm:hidden cursor-pointer" onClick={onClose}>
            <Icon name="Xclose" />
          </div>
        </div>
        <TabGroup className="">
          <div className="flex justify-between items-center w-full items-center mb-4 mt-4">
            <TabList
              variant="solid"
              className="bg-transparent font-[600] text-[14px] text-[#001C44]"
            >
              <Tab>General</Tab>
              {/* <Tab disabled={isDisabled}>Data</Tab> */}
              <Tab disabled={isDisabled}>Display</Tab>
            </TabList>
          </div>
          <TabPanels>
            <TabPanel>
              <div className="p-0 sm:p-2 h-full flex flex-col justify-between">
                <div className="max-h-[80dvh] sm:max-h-[73dvh] overflow-auto scrollbar-thin scrollbar-thumb-gray-300 scrollbar-track-gray-100 scrollbar-rounded-8">
                  <div className="flex flex-col w-full gap-2 items-start mb-4">
                    <label className="block text-sm font-medium text-gray-900 dark:text-white">
                      Metrics
                    </label>
                    <div className="w-full">
                      <ReactSelect
                        // options={metricSourceOptions}
                        options={metricSourceOptionsPerService[serviceLine]}
                        value={selectedSourceMetric}
                        // isDisabled={selectedItem?.data_config?.metrics ? true : false}
                        handleOnChange={(selectedOption: any) => {
                          setSelectedSourceMetric(selectedOption);
                          setSelectedMetric(null);
                        }}
                      />
                    </div>
                    <div className="w-full">
                      <ReactSelect
                        options={getMetricSourceOptions(
                          selectedSourceMetric?.value
                        )}
                        value={selectedMetric}
                        // isDisabled={selectedItem?.data_config?.metrics ? true : false}
                        handleOnChange={(selectedOption: any) => {
                          setSelectedMetric(selectedOption);
                          setIsDisabled(false);
                          setProperty(selectedOption?.label)
                        }}
                      />
                    </div>
                  </div>
                  <div className="relative">
                    <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                      Title
                    </label>
                    <input
                      type="text"
                      value={title || ''}
                      onChange={(e) => setTitle(e.target.value)}
                      maxLength={50}
                      className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    />
                  </div>
                  <div className="my-4">
                    <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                      Tooltip
                    </label>
                    <p className='text-sm'>
                      {getMetricsTooltip(selectedSourceMetric?.value,selectedMetric?.value)}
                    </p>
                  </div>
                  <div className="mt-4">
                    <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                      Display Type
                    </label>
                    <div className="flex flex-wrap gap-4 w-full">
                      {[
                        "landingPage","organic_landingPage",
                        // semrush
                        "organic_search_positions","ranking_overview","traffic_competitors",
                        // gsc
                        "queries","pages",
                        // ahref
                        "backlinks",
                        // Google ads
                        "campaigns", "adGroups", "keywordsView", "adGroupAds"
                      ].includes(selectedMetric?.value) ?
                      <>
                        <div
                          className={`
                              cursor-pointer flex items-center flex-col justify-center w-[22%] hover:bg-[#D0EDFF] hover:border-[#0029FF] border h-24 rounded-md
                              ${chartType === 'table' ? 'bg-[#D0EDFF] border-[#0029FF]' : 'bg-white border-[#D0D5DD]'}
                            `}
                          onClick={() => setChartType('table')}
                        >
                          <Icon name="tableView" />
                          <h4 className="text-sm font-semibold mt-2">Table</h4>
                        </div>
                      </> 
                      : [
                          // semrush
                          "Organic Keywords","Organic Traffic",
                          "Organic Cost"
                        ].includes(selectedMetric?.value) ?
                        <>
                          <div
                          className={`
                              cursor-pointer flex items-center flex-col justify-center w-[22%] hover:bg-[#D0EDFF] hover:border-[#0029FF] border h-24 rounded-md
                              ${chartType === 'value' ? 'bg-[#D0EDFF] border-[#0029FF]' : 'bg-white border-[#D0D5DD]'}
                            `}
                          onClick={() => setChartType('value')}
                        >
                          <Icon name="lineChartUp" />
                          <h4 className="text-sm font-semibold mt-2">Value</h4>
                        </div>
                        </>
                        : [
                            "top_traffic_sources","new_users","campaignDevices"
                          ].includes(selectedMetric?.value)
                        ? <>
                            <div
                              className={`
                                  cursor-pointer flex items-center flex-col justify-center w-[22%] hover:bg-[#D0EDFF] hover:border-[#0029FF] border h-24 rounded-md
                                  ${chartType === 'pie' ? 'bg-[#D0EDFF] border-[#0029FF]' : 'bg-white border-[#D0D5DD]'}
                                `}
                              onClick={() => setChartType('pie')}
                            >
                              <Icon name="pieIconChart" />
                              <h4 className="text-sm font-semibold mt-2">Pie</h4>
                            </div>
                          </>
                        :
                      <>
                        <div
                          className={`cursor-pointer flex items-center flex-col justify-center w-[22%] hover:bg-[#D0EDFF] hover:border-[#0029FF] border h-24 rounded-md
                            ${chartType === 'area' ? 'bg-[#D0EDFF] border-[#0029FF]' : 'bg-white border-[#D0D5DD]'}`}
                          onClick={() => setChartType('area')}
                        >
                          <Icon name="areaIconChart" />
                          <h4 className="text-sm font-semibold mt-2">Area</h4>
                        </div>
                        <div
                          className={`
                              cursor-pointer flex items-center flex-col justify-center w-[22%] hover:bg-[#D0EDFF] hover:border-[#0029FF] border h-24 rounded-md
                              ${chartType === 'line' ? 'bg-[#D0EDFF] border-[#0029FF]' : 'bg-white border-[#D0D5DD]'}
                            `}
                          onClick={() => setChartType('line')}
                        >
                          <Icon name="lineIconChart" />
                          <h4 className="text-sm font-semibold mt-2">Line</h4>
                        </div>
                        <div
                          className={`cursor-pointer flex items-center flex-col justify-center w-[22%] hover:bg-[#D0EDFF] hover:border-[#0029FF] border h-24 rounded-md
                            ${chartType === 'bar' ? 'bg-[#D0EDFF] border-[#0029FF]' : 'bg-white border-[#D0D5DD]'}`}
                          onClick={() => setChartType('bar')}
                        >
                          <Icon name="BarChart" color="#0029FF" />
                          <h4 className="text-sm font-semibold mt-2">Bar</h4>
                        </div>
                        
                      </>
                      }
                    </div>
                  </div>
                </div>
              </div>
            </TabPanel>
            {/* <TabPanel>
              <div className="p-0 sm:p-2 h-full flex flex-col justify-between">
                <div className="max-h-[80dvh] sm:max-h-[73dvh] overflow-auto scrollbar-thin scrollbar-thumb-gray-300 scrollbar-track-gray-100 scrollbar-rounded-8">
                <div className="flex flex-col w-full gap-2 items-start mt-4">
                    <label className="block text-sm font-medium text-gray-900 dark:text-white">
                      Metrics
                    </label>
                    <div className="w-full">
                      <ReactSelect
                        options={metricSourceOptions}
                        value={selectedSourceMetric}
                        // isDisabled={selectedItem?.data_config?.metrics ? true : false}
                        handleOnChange={(selectedOption: any) => {
                          setSelectedSourceMetric(selectedOption);
                          setSelectedMetric(null);
                        }}
                      />
                    </div>
                    <div className="w-full">
                      <ReactSelect
                        options={getMetricSourceOptions(
                          selectedSourceMetric?.value
                        )}
                        value={selectedMetric}
                        // isDisabled={selectedItem?.data_config?.metrics ? true : false}
                        handleOnChange={(selectedOption: any) => {
                          setSelectedMetric(selectedOption);
                          setIsDisabled(false);
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </TabPanel> */}
            <TabPanel>
              <div className="p-0 sm:p-2 h-full flex flex-col justify-between">
                <div className="h-screen overflow-auto scrollbar-thin scrollbar-thumb-gray-300 scrollbar-track-gray-100 scrollbar-rounded-8">
                  <div className="flex items-center gap-4 w-full jutify-between">
                    <ReactSelect 
                      options={[
                        ...intervals
                      ]}
                      value={interval}
                      handleOnChange={(selectedOption: any) => {
                        if (selectedOption.value === 'daily') {
                          setCustomStartDate(today)
                          setCustomEndDate(
                            moment(today).add(30, 'days')
                          );
                        }
                        if (selectedOption.value === 'weekly') {
                          const sundayDate = getNearestSunday(today);
                          setCustomStartDate(sundayDate);
                          setCustomEndDate(
                            moment(sundayDate).add(6, 'days')
                          );
                        }
                        if (selectedOption.value === 'monthly') {
                          setCustomStartDate(moment(customStartDate).startOf('month'))
                          const futureDate = moment(customStartDate).add(13, "months");
                          setCustomEndDate(futureDate.endOf('month'));
                        }
                        setInterval(selectedOption);
                      }}
                    />
                  </div>
                  <div className="flex items-center gap-4 w-full jutify-between mb-4">
                    {/* Daily interval */}
                    {interval?.value === "daily" &&
                      <div className="my-2 flex flex-row justify-start items-center gap-4 w-full">
                        <div className="w-full sm:w-1/2">
                          <label className="block text-sm font-medium text-gray-900 dark:text-white">Start Date</label>
                          <DatePicker 
                            selected={moment(
                              customStartDate,
                              'YYYY-MM-DD'
                            ).toDate()}
                            minDate={today}
                            onChange={(date) => {
                              if (date !== null) {
                                setCustomStartDate(date)
                              }
                            }}
                            className="w-full pl-2 react-datepicker__month-container text-left h-[35px] border border-[#D1D5DB] cursor-pointer dark:bg-[#374151] dark:text-white dark:border-none mt-2 font-inter rounded-lg text-[14px] text-[#525252]"
                          />
                        </div>
                        <div className="w-full sm:w-1/2">
                          <label className="block text-sm font-medium text-gray-900 dark:text-white">End Date</label>
                          <DatePicker 
                            selected={moment(
                              customEndDate,
                              'YYYY-MM-DD'
                            ).toDate()}
                            minDate={today}
                            maxDate={new Date(customEndDate)}
                            onChange={(date) => {
                              if (date !== null) {
                                setCustomEndDate(date)
                              }
                            }}
                            className="w-full pl-2 react-datepicker__month-container text-left h-[35px] border border-[#D1D5DB] cursor-pointer dark:bg-[#374151] dark:text-white dark:border-none mt-2 font-inter rounded-lg text-[14px] text-[#525252]"
                          />
                        </div>
                      </div>
                    }
                    {/* Weekly interval */}
                    {interval?.value === "weekly" &&
                      <div className="my-2 flex flex-row justify-start items-center gap-4 w-full">
                        <div className="w-full sm:w-1/2">
                          <label className="block text-sm font-medium text-gray-900 dark:text-white">Start Date</label>
                          <DatePicker
                            selected={moment(
                              customStartDate,
                              'YYYY-MM-DD'
                            ).toDate()}
                            minDate={today}
                            onChange={(date) => {
                              if (date !== null) {
                                setCustomStartDate(date);
                              }
                            }}
                            filterDate={(date) => date.getDay() === 0}
                            className="w-full pl-2 react-datepicker__month-container text-left h-[38px] border border-[#D1D5DB] cursor-pointer dark:bg-[#374151] dark:text-white dark:border-none mt-2 font-inter rounded-lg text-[14px] text-[#525252]"
                          />
                        </div>
                        <div className="w-full sm:w-1/2">
                          <label className="block text-sm font-medium text-gray-900 dark:text-white">End Date</label>
                          <DatePicker
                            selected={moment(
                              customEndDate,
                              'YYYY-MM-DD'
                            ).toDate()}
                            minDate={customStartDate}
                            onChange={(date) => {
                              if (date !== null) {
                                setCustomEndDate(date);
                              }
                            }}
                            filterDate={(date) => date.getDay() === 6}
                            className="w-full react-datepicker__month-container text-left pl-2 h-[38px] border border-[#D1D5DB] cursor-pointer  dark:bg-[#374151] dark:text-white dark:border-none mt-2 font-inter rounded-lg text-[14px] text-[#525252]"
                          />
                        </div>
                      </div>
                    }
                    {/* Monthly interval */}
                    {interval?.value === "monthly" &&
                      <div className="my-2 flex flex-row justify-start items-center gap-4 w-full">
                        <div className="w-full sm:w-1/2">
                          <label className="block text-sm font-medium text-gray-900 dark:text-white">Start Date</label>
                          <DatePicker
                            selected={moment(
                              customStartDate,
                              'YYYY-MM-DD'
                            ).toDate()}
                            minDate={today}
                            onChange={(date) => {
                              if (date !== null) {
                                setCustomStartDate(date)
                              }
                            }}
                            className="w-full sm:w-[22.3vh] pl-2 react-datepicker__month-container text-left h-[38px] border border-[#D1D5DB] cursor-pointer dark:bg-[#374151] dark:text-white dark:border-none mt-2 font-inter rounded-lg text-[14px] text-[#525252]"
                            showMonthYearPicker={true}
                            dateFormat={'MM/yyyy'}
                          />
                        </div>
                        <div className="w-full sm:w-1/2">
                          <label className="block text-sm font-medium text-gray-900 dark:text-white">End Date</label>
                          <DatePicker
                            selected={moment(
                              customEndDate,
                              'YYYY-MM-DD'
                            ).toDate()}
                            minDate={today}
                            maxDate={new Date(customEndDate)}
                            onChange={(date) => {
                              if (date !== null) {
                                setCustomEndDate(moment(date).endOf('month'))
                              }
                            }}
                            className="w-full sm:w-[22.3vh] pl-2 react-datepicker__month-container text-left h-[38px] border border-[#D1D5DB] cursor-pointer dark:bg-[#374151] dark:text-white dark:border-none mt-2 font-inter rounded-lg text-[14px] text-[#525252]"
                            showMonthYearPicker={true}
                            dateFormat={'MM/yyyy'}
                          />
                        </div>
                      </div>
                    }
                  </div>

                  <div className="flex items-center gap-4 w-full jutify-between">
                    <label className="block text-sm text-gray-900 dark:text-white">
                      Show trendline
                    </label>
                    <label className="relative inline-flex items-center justify-center align-center cursor-pointer">
                      <input
                        type="checkbox"
                        className="sr-only peer"
                        checked={trendLine}
                        onChange={(e) => setTrendLine(e.target.checked)}
                      />
                      <div className="w-9 h-5 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-4 after:w-4 after:transition-all dark:border-gray-600 peer-checked:bg-[#005C9F]"></div>
                    </label>
                  </div>
                  <div className="flex items-center gap-4 mt-4">
                    <label className="block text-sm text-gray-900 dark:text-white">
                      Show Integration Icon
                    </label>
                    <label className="relative inline-flex items-center justify-center align-center cursor-pointer">
                      <input
                        type="checkbox"
                        className="sr-only peer"
                        checked={integrationIcon}
                        onChange={(e) => setIntegrationIcon(e.target.checked)}
                      />
                      <div className="w-9 h-5 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-4 after:w-4 after:transition-all dark:border-gray-600 peer-checked:bg-[#005C9F]"></div>
                    </label>
                  </div>
                  <div className="flex items-center gap-4 mt-4">
                    <label className="block text-sm text-gray-900 dark:text-white">
                      Show Data Labels
                    </label>
                    <label className="relative inline-flex items-center justify-center align-center cursor-pointer">
                      <input
                        type="checkbox"
                        className="sr-only peer"
                        checked={dataLabels}
                        onChange={(e) => setDataLabels(e.target.checked)}
                      />
                      <div className="w-9 h-5 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-4 after:w-4 after:transition-all dark:border-gray-600 peer-checked:bg-[#005C9F]"></div>
                    </label>
                  </div>
                  <div className="mt-4">
                    <label className="block text-sm text-gray-900 dark:text-white mb-2">
                      Theme Color
                    </label>
                    <select
                      value={themeColor}
                      onChange={(e) => setThemeColor(e.target.value)}
                      className="w-full p-2 rounded-md border-2 border-gray-300 dark:border-gray-600 focus:border-blue-500 focus:ring focus:ring-blue-200 dark:focus:ring-blue-700 focus:outline-none"
                    >
                      <option value="#0029FF">Cobalt</option>
                      <option value="#009EFF">Mid Blue</option>
                      <option value="#FFB545">Mango</option>
                      <option value="#01F0FF">Neon</option>
                      <option value="#001C44">Navy</option>
                      <option value="#ADF8FF">Blue Highlight</option>
                      <option value="#D0EDFF">Ice</option>
                    </select>
                  </div>

                  <div className="mt-4">
                    <label className="block text-sm text-gray-900 dark:text-white mb-2">
                      Theme Style
                    </label>
                    <select
                      value={themeStyle}
                      className="w-full p-2 rounded-md"
                      onChange={(e) => setThemeStyle(e.target.value)}
                    >
                      <option value="light">Light</option>
                      <option value="dark">Dark</option>
                    </select>
                  </div>
                </div>
              </div>
            </TabPanel>
          </TabPanels>
        </TabGroup>
        {error?.status && (
          <p className="text-sm text-red-500">{error?.message}</p>
        )}
        <div className="absolute bottom-4 flex flex-row justify-between w-72">
          <button
            className="w-2/5 mr-2 text-white bg-[#0029FF] text-[16px] font-[600] rounded-lg p-2"
            onClick={handleSave}
          >
            Save
          </button>
          {selectedItem?.title?.length > 0
            ? <button
              className="w-2/5 ml-2 text-white bg-red-700 hover:bg-red-800 text-[16px] font-[600] rounded-lg p-2"
              onClick={handleDelete}
            >
              Delete
            </button>
            : title?.length !== 0 ?
              <button
                className="w-2/5 ml-2 text-white bg-red-700 hover:bg-red-800 text-[16px] font-[600] rounded-lg p-2"
                onClick={onClose}
              >
                Cancel
              </button>
          : null
          }
        </div>
      </div>
    </div>
  );
};

export default WidgetSettings;
