import { BarChart } from '@tremor/react';
import { convertKey } from '../constants';

interface IProps {
  data?: any;
  category?: string[];
  displayConfig?: any;
  viewType?: string;
  source?: string;
}

const chartdata = [
  {
    date: "Jan 23",
    SolarPanels: 2890,
  },
  {
    date: "Feb 23",
    SolarPanels: 2756,
  },
  {
    date: "Mar 23",
    SolarPanels: 3322,
  },
  {
    date: "Apr 23",
    SolarPanels: 3470,
  },
  {
    date: "May 23",
    SolarPanels: 3475,
  },
  {
    date: "Jun 23",
    SolarPanels: 3129,
  },
  {
    date: "Jul 23",
    SolarPanels: 3490,
  },
  {
    date: "Aug 23",
    SolarPanels: 2903,
  },
  {
    date: "Sep 23",
    SolarPanels: 2643,
  },
  {
    date: "Oct 23",
    SolarPanels: 2837,
  },
  {
    date: "Nov 23",
    SolarPanels: 2954,
  },
  {
    date: "Dec 23",
    SolarPanels: 3239,
  },
];

const BarChartDisplay = ({ 
  data, 
  category, 
  displayConfig, 
  viewType, 
  source 
}: IProps) => {
  return (
    <div>
      <BarChart
        data={["preview","edit"].includes(viewType) ? convertKey(chartdata,category) : data}
        index="date"
        categories={category}
        colors={[displayConfig?.themeColor]}
        showLegend={false}
        yAxisWidth={60}
        showYAxis={false}
        startEndOnly={true}
        className="-mb-2 mt-8 h-48"
      />
    </div>
  );
};

export default BarChartDisplay;
