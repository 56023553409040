import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import Icon from '../../../assets/icons/SvgComponent';
import { Input } from './myDetails';
import { toast } from 'react-toastify';
import { getOmgRoles, getClientRoles, isAdminRole } from '../../../utils';
import { emailValidation } from '../../../utils/validation';
import { useThemeStore } from '../../../store/useThemeStore';
import useClientStore from '../../../store/useClientStore';
import useUserStore from '../../../store/useUserStore';
import ImageIcon from '../../ImageIcon';
import Alert from '../../Alert';
import DropdownUser from '../../DropdownUser';
import ReactSelect from '../../ReactSelect';
import UploadImage from '../../Upload';

interface EditModalProps {
  user?: any;
  type?: string;
  manageClients?: any;
  clientDetails?: any;
  onClose?: any;
}

type FormState = {
  first_name?: string;
  last_name?: string;
  email?: string;
  password?: string;
  hubspot_id: string;
  teamwork_id: string;
  gurulytics_role?: string;
  rum_target?: number;
  monthly_insights_report?: boolean;
  clients?: Array<any>;
  phone?: string;
  role?: any;
};

const EditUserModal: React.FC<EditModalProps> = ({
  user,
  type,
  manageClients,
  clientDetails,
  onClose,
}) => {
  const { mode } = useThemeStore((state: any) => state);
  const iconColor = mode === 'dark' ? 'white' : 'black';
  const pathName = useLocation();
  const pathString = pathName?.toString() || '';

  const { fetchClients, clients } = useClientStore((state) => state);
  const { createUser, updateUser } = useUserStore((state) => state);
  const [searchClientText, setSearchClientText] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [showAllClients, setShowAllClients] = useState(false);
  const [formData, setFormData] = useState<FormState>(user);
  const [openDropdown, setOpenDropdown] = useState(false);
  let clientsToShow: { name: string; id: string }[] = [];
  if (formData && formData.clients && Array.isArray(formData.clients)) {
    if (showAllClients) {
      clientsToShow = formData?.clients;
    } else {
      clientsToShow = formData?.clients?.slice(0, 10);
    }
  }

  const getUserInitials = (name: string) => {
    const nameParts = name?.split(' ');
    let initials = '';

    if (nameParts.length > 0) {
      initials += nameParts[0][0];

      if (nameParts.length > 1) {
        initials += nameParts[1][0];
      }
    }

    return initials;
  };

  const toggleShowAll = () => {
    setShowAllClients(!showAllClients);
  };

  useEffect(() => {
    if (searchClientText?.length > 2) {
      autoCompleteFetchClients();
    }
    if (searchClientText === '') {
      setOpenDropdown(false);
    }
  }, [searchClientText]);

  //autocomplete fetch clients
  const autoCompleteFetchClients = () => {
    const filters = {
      status: 'all',
      search: searchClientText,
      page: 1,
      limit: 100,
    };
    fetchClients(filters);
  };

  const deleteClient = async (client: any) => {
    const clients = formData?.clients?.filter((c: any) => c.id !== client.id);
    await updateUser(user.id, {data: { clients }});

    setFormData((prevFormData) => {
      const newClients = prevFormData.clients.filter(
        (c: any) => c.id !== client.id
      );
      return { ...prevFormData, clients: newClients };
    });
  };

  const handleUpdateUser = () => {
    const isTrueSet = String(formData.monthly_insights_report) === 'true';
    let data: FormState = {
      first_name: formData?.first_name,
      last_name: formData?.last_name,
      email: formData?.email,
      password: formData?.password,
      hubspot_id: formData?.hubspot_id,
      teamwork_id: formData?.teamwork_id,
      rum_target: formData?.rum_target,
      phone: formData?.phone,
    };

    if (formData?.clients?.length > 0) {
      data.clients = formData?.clients?.map((client) => client.id);
    }

    if (!clientDetails) {
      data.gurulytics_role = formData?.gurulytics_role;
      data.monthly_insights_report = isTrueSet;
    } else {
      data.role = formData?.role;
    }
    if (formData?.clients?.length > 0) {
      data.clients = formData?.clients?.map((client) => client.id);
    }
    if (user.id) {
      setIsLoading(true);
      updateUser(user.id, data)
        .then((res: any) => {
          setIsLoading(false);
          toast.success(
            <Alert
              icon=""
              title={`USER UPDATE`}
              message={`User has been updated successfully`}
            />,
            {
              position: 'top-right',
              autoClose: 2000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            }
          );
        })
        .catch((e: any) => {
          setErrorMessage(e.message);
          toast.error(
            <Alert icon="" title={`ERROR MESSAGE!`} message={`${e.message}`} />,
            {
              position: 'top-right',
              autoClose: 2000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            }
          );
          setIsLoading(false);
        });
    } else {
      createUser(data).catch((e: any) =>
        toast.error(
          <Alert icon="" title={`ERROR MESSAGE!`} message={`${e.message}`} />,
          {
            position: 'top-right',
            autoClose: 2000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          }
        )
      );
    }
  };

  const handleSubmit = () => {
    if (
      !formData.first_name ||
      !formData.last_name ||
      !formData?.gurulytics_role ||
      emailValidation(formData?.email)
    ) {
      toast.error(
        <Alert
          icon=""
          title={`ERROR MESSAGE!`}
          message={`${
            !formData?.first_name
              ? 'First Name is required'
              : !formData?.last_name
                ? 'Last Name is required'
                : !formData?.gurulytics_role
                  ? 'Role is required'
                  : emailValidation(formData?.email)
          }`}
        />,
        {
          position: 'top-right',
          autoClose: 2000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        }
      );
    } else {
      handleUpdateUser();
    }
  };

  const handleChange = (event: any) => {
    if (event.target.name === 'client') {
      setSearchClientText(event.target.value);
      setOpenDropdown(true);
    } else {
      const { name, value } = event.target;
      setFormData((prevState) => ({ ...prevState, [name]: value }));
    }
  };

  let roles = type === 'omg' ? getOmgRoles() : getClientRoles();
  roles = roles.filter((item) => item.id !== 'all');

  return (
    <div
      className="fixed top-0 right-0 bg-white h-screen w-[26rem] flex flex-col z-100 dark:bg-[#262627] dark:border dark:border-[#313233] dark:shadow-bs-card font-inter"
      style={{ minHeight: '720px' }}
      onClick={(e) => e.stopPropagation()}
    >
      <div className="relative h-screen overflow-y-hidden flex flex-col justify-between">
        <div className="max-h-[100dvh] overflow-y-hidden">
          <div className="px-6 flex  items-start gap-2  border-b border-tertiary-colours-neutral-cool-100 flex justify-between">
            <h2 className="font-inter text-[#001129] text-xl font-semibold my-6  dark:text-[#E6EFF5]  w-[90px]">
              Edit User
            </h2>
            <div className="my-4 overflow-hidden">
              {isLoading && <Icon name="Loading" />}
            </div>
            <div
              className="w-[90px] h-full flex justify-end my-6 cursor-pointer"
              onClick={onClose}
            >
              <Icon
                name="Xclose"
                color={mode === 'dark' ? '#fff' : iconColor}
              />
            </div>
          </div>
          <div className="flex flex-col h-1/2 mt-2 min-h-[85dvh] overflow-auto scrollbar-thin scrollbar-thumb-gray-300 scrollbar-track-gray-100">
            <div className="px-6 mb-2">
              <span className="text-sm text-[#344054] dark:text-[#E6EFF5]">
                Profile Picture
              </span>
            </div>

            {/* Upload user image */}
            <UploadImage user={user} />
            
            <form className="relative flex flex-col space-y-4 py-4  px-6  ">
              <Input
                label="First Name"
                type="text"
                name="first_name"
                value={formData?.first_name}
                onChange={(e) => handleChange(e)}
              />
              <Input
                label="Last Name"
                type="text"
                name="last_name"
                value={formData?.last_name}
                onChange={(e) => handleChange(e)}
              />
              <Input
                label="Email address"
                type="email"
                name="email"
                value={formData?.email}
                disabled
                onChange={(e) => handleChange(e)}
              />
              <Input
                label="Password"
                type="password"
                name="password"
                value={formData?.password}
                onChange={(e) => handleChange(e)}
              />
              <Input
                label="Hubspot ID"
                type="hubspot_id"
                name="hubspot_id"
                value={formData?.hubspot_id}
                onChange={(e) => handleChange(e)}
              />
              <Input
                label="Teamwork ID"
                type="teamwork_id"
                name="teamwork_id"
                value={formData?.teamwork_id}
                onChange={(e) => handleChange(e)}
              />
              <Input
                label="RuM Target"
                type="rum_target"
                name="rum_target"
                value={formData?.rum_target}
                onChange={(e) => handleChange(e)}
              />
              {clientDetails ? (
                <div>
                  <label className="block  text-sm font-medium text-gray-900 dark:text-white">
                    Role
                  </label>
                  <select
                    className=" border border-gray-600 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full px-1 py-3 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    name={'role'}
                    value={formData?.role}
                    onChange={(e) => handleChange(e)}
                  >
                    {roles.map((role, i) => (
                      <option
                        className="p-[20px] text-lg font-inter font-medium"
                        key={i}
                        value={role.id}
                      >
                        {role.label}
                      </option>
                    ))}
                  </select>
                </div>
              ) : (
                <div>
                  <label className="block  text-sm font-medium text-gray-900 dark:text-white">
                    Role
                  </label>
                  <select
                    className=" border border-gray-600 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full px-1 py-3 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    name={'gurulytics_role'}
                    value={formData?.gurulytics_role}
                    onChange={(e) => handleChange(e)}
                  >
                    {roles.map((role, i) => (
                      <option
                        className="p-[20px] text-lg font-inter font-medium"
                        key={i}
                        value={role.id}
                      >
                        {role.label}
                      </option>
                    ))}
                  </select>
                </div>
              )}
              {manageClients ? (
                <Input
                  label="Hubspot Role"
                  type="role"
                  name="role"
                  disabled
                  value={formData?.role?.name}
                  onChange={(e) => handleChange(e)}
                />
              ) : null}
            </form>
            {manageClients ? (
              <>
                {!isAdminRole(formData?.gurulytics_role) ? (
                  <div className="px-6 pb-8">
                    <div className="border-b border-gray-400">
                      <div className="w-full dark:text-[#E6EFF5]  py-2">
                        <h3 className="mb-2">Manage Clients</h3>
                        <p className="text-sm text-gray-600 font-light mb-2">
                          Search for clients.
                        </p>
                        <div>
                          <label
                            htmlFor="default-search"
                            className="mb-2 text-sm font-medium text-gray-900 sr-only dark:text-white"
                          >
                            Search
                          </label>
                          <div className="relative">
                            <div className="absolute top-0 inset-y-0 start-0 flex items-center ps-3 pointer-events-none max-h-[42px]">
                              <Icon name="User01" color="#667085" />
                            </div>
                            <input
                              type="search"
                              className="block w-full px-1 py-2 ps-10 text-md text-gray-900 border border-gray-600 rounded-lg   dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                              placeholder="Select Clients"
                              name="client"
                              value={searchClientText}
                              onChange={(e) => handleChange(e)}
                            />
                            <div className={openDropdown ? 'mb-12' : ''}>
                              {openDropdown && (
                                <DropdownUser
                                  onSelect={(value: { name: any; id: any }) => {
                                    setFormData({
                                      ...formData,
                                      clients: [
                                        ...formData?.clients,
                                        { name: value?.name, id: value?.id },
                                      ],
                                    });
                                    setOpenDropdown(false);
                                  }}
                                  items={clients?.data}
                                  styles={undefined}
                                  selected={undefined}
                                />
                              )}
                            </div>
                          </div>
                        </div>
                        <p className="text-sm text-gray-600 font-light mt-2">
                          {user.first_name} has access to the following clients.
                        </p>
                      </div>
                    </div>
                    {clientsToShow.length === 0 && (
                      <div className="py-6 w-full flex justify-center dark:text-white">
                        No clients found.
                      </div>
                    )}
                    {Array.isArray(clientsToShow) &&
                      clientsToShow.map((client, index) => (
                        <div
                          className="flex items-center p-3 text-base font-bold text-gray-900 rounded-lg  hover:bg-gray-100 group hover:shadow dark:bg-gray-600 dark:hover:bg-gray-500 dark:text-white my-2"
                          key={index}
                        >
                          <div className="w-8 h-8 rounded-full overflow-hidden">
                            <div className="bg-[#345578] text-white flex items-center justify-center w-full h-full text-xs uppercase">
                              {getUserInitials(client?.name)}
                            </div>
                          </div>
                          <span className="text-sm flex-1 ms-3 overflow-hidden whitespace-nowrap text-[ellipsis]">
                            {client?.name}
                          </span>
                          <span
                            className="inline-flex items-center justify-center px-2 py-0.5 ms-3 text-sm  text-[#B42318]  rounded dark:bg-gray-700 dark:text-gray-400 cursor-pointer"
                            onClick={() => deleteClient(client)}
                          >
                            Remove
                          </span>
                        </div>
                      ))}

                    {formData?.clients?.length > 10 && (
                      <button
                        onClick={toggleShowAll}
                        className="text-sm text-[#005C9F] p-4"
                      >
                        {showAllClients
                          ? 'Collapse'
                          : `Expand ${formData?.clients?.length - 10} more`}
                      </button>
                    )}
                  </div>
                ) : (
                  <></>
                )}
              </>
            ) : (
              <></>
            )}
          </div>
        </div>

        <div className="px-6 min-h-[8vh] max-h-[11vh] w-full flex justify-center items-center border-t border-tertiary-colors-neutral-cool-100">
          <button
            type="button"
            onClick={handleSubmit}
            className="w-full rounded-lg text-white border border-[#005C9F] bg-[#005C9F] shadow-xs px-4 py-2 dark:text-[#E6EFF5]"
          >
            Update
          </button>
        </div>
      </div>
    </div>
  );
};

export default EditUserModal;
