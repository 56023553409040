import { Card, Grid, Col } from '@tremor/react';
import DonutBarGroup from './donutBarGroup';
import InfoTooltip from '../../AnalyticsSections/AnalyticsInfoTooltip';
import Icon from '../../../assets/icons/SvgComponent';
import Tooltip from '../../HelpTooltip';
import useClientStore from '../../../store/useClientStore';

interface IProps {
  data?: any;
  isLoading?: boolean;
}

const GoogleAdsDevicesOverview = ({
  data,
  isLoading
}: IProps) => {
  const { selectedClient } = useClientStore(state => state);
  const devicesResponse = data ?? [];
  

  const clicks = devicesResponse?.map((item) => ({
    name: item.device,
    value: item?.clicks,
  }));
  const conversions = devicesResponse?.map((item) => ({
    name: item.device,
    value: item?.conversions,
  }));
  const costs = devicesResponse?.map((item) => ({
    name: item.device,
    value: item?.cost,
  }));
  const impressions = devicesResponse?.map((item) => ({
    name: item.device,
    value: item?.impressions,
  }));

  const devices = devicesResponse?.map((item) => item.device)

  return (
    <div className="w-full flex flex-col gap-8">
      <Grid numItemsMd={1} numItemsLg={2} className="gap-4 mt-4">
        <Col>
          <Card className={`border-none shadow-none ring-0 lg:ring-1 lg:border dark:bg-dark-tremor-background p-[16px] relative h-auto`}>
            <div className="flex items-center justify-between pb-[0.8rem] sm:pb-0">
              <div className="flex gap-2 items-center mb-1 break-all">
                <h2 className={`text-[14px] text-[#001C44] darl:text-white'}  dark:text-white font-bold`}>
                  Clicks
                </h2>
                <InfoTooltip content="Clicks per device" position="bottom" >
                  <Icon name="InfoCircle" size={14} />
                </InfoTooltip>
              </div>
              <div className="flex items-center">
                <Tooltip
                  content={`Customer ID: ${selectedClient?.google_ads?.customer_id ? selectedClient?.google_ads?.customer_id : 'N/A'}`}
                  position="left"
                  textAlign="text-center"
                >
                  <div className="pt-1">
                    <Icon name={"googleAd"} size={20} />
                  </div>
                </Tooltip>
              </div>
            </div>
            <DonutBarGroup 
              data={clicks}
              categories={devices}
              variant="pie"
              category="value"
              index="name"
              showLegend={true}
            />
          </Card>
        </Col>
        <Col>
          <Card className={`border-none shadow-none ring-0 lg:ring-1 lg:border dark:bg-dark-tremor-background p-[16px] relative h-auto`}>
            <div className="flex items-center justify-between pb-[0.8rem] sm:pb-0">
              <div className="flex gap-2 items-center mb-1 break-all">
                <h2 className={`text-[14px] text-[#001C44] darl:text-white'}  dark:text-white font-bold`}>
                  Conversions
                </h2>
                <InfoTooltip content="Conversions per device" position="bottom" >
                  <Icon name="InfoCircle" size={14} />
                </InfoTooltip>
              </div>
              <div className="flex items-center">
                <Tooltip
                  content={`Customer ID: ${selectedClient?.google_ads?.customer_id ? selectedClient?.google_ads?.customer_id : 'N/A'}`}
                  position="left"
                  textAlign="text-center"
                >
                  <div className="pt-1">
                    <Icon name={"googleAd"} size={20} />
                  </div>
                </Tooltip>
              </div>
            </div>
            <DonutBarGroup 
              data={conversions}
              categories={devices}
              variant="pie"
              category="value"
              index="name"
              showLegend={true}
            />
          </Card>
        </Col>
        <Col>
          <Card className={`border-none shadow-none ring-0 lg:ring-1 lg:border dark:bg-dark-tremor-background p-[16px] relative h-auto`}>
            <div className="flex items-center justify-between pb-[0.8rem] sm:pb-0">
              <div className="flex gap-2 items-center mb-1 break-all">
                <h2 className={`text-[14px] text-[#001C44] darl:text-white'}  dark:text-white font-bold`}>
                  Cost
                </h2>
                <InfoTooltip content="Cost per device" position="bottom" >
                  <Icon name="InfoCircle" size={14} />
                </InfoTooltip>
              </div>
              <div className="flex items-center">
                <Tooltip
                  content={`Customer ID: ${selectedClient?.google_ads?.customer_id ? selectedClient?.google_ads?.customer_id : 'N/A'}`}
                  position="left"
                  textAlign="text-center"
                >
                  <div className="pt-1">
                    <Icon name={"googleAd"} size={20} />
                  </div>
                </Tooltip>
              </div>
            </div>
            <DonutBarGroup 
              data={costs}
              categories={devices}
              variant="pie"
              category="value"
              index="name"
              showLegend={true}
            />
          </Card>
        </Col>
        <Col>
          <Card className={`border-none shadow-none ring-0 lg:ring-1 lg:border dark:bg-dark-tremor-background p-[16px] relative h-auto`}>
            <div className="flex items-center justify-between pb-[0.8rem] sm:pb-0">
              <div className="flex gap-2 items-center mb-1 break-all">
                <h2 className={`text-[14px] text-[#001C44] darl:text-white'}  dark:text-white font-bold`}>
                  Impressions
                </h2>
                <InfoTooltip content="Impressions per device" position="bottom" >
                  <Icon name="InfoCircle" size={14} />
                </InfoTooltip>
              </div>
              <div className="flex items-center">
                <Tooltip
                  content={`Customer ID: ${selectedClient?.google_ads?.customer_id ? selectedClient?.google_ads?.customer_id : 'N/A'}`}
                  position="left"
                  textAlign="text-center"
                >
                  <div className="pt-1">
                    <Icon name={"googleAd"} size={20} />
                  </div>
                </Tooltip>
              </div>
            </div>
            <DonutBarGroup 
              data={impressions}
              categories={devices}
              variant="pie"
              category="value"
              index="name"
              showLegend={true}
            />
          </Card>
        </Col>
      </Grid>
    </div>
  )
}

export default GoogleAdsDevicesOverview