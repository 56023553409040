import { useMemo } from "react";
import Tooltip from "../../HelpTooltip";
import Icon from '../../../assets/icons/SvgComponent';
import { formatNumber, formatToCurrency } from "../../../utils/common";
import AnalyticsTable from "../../AnalyticsSections/AnalyticsTable";
import useClientStore from "../../../store/useClientStore";

interface IProps {
  data?: any;
  isLoading?: boolean;
  sectionType?: string;
}

const CampaignAndImpressionShareOverview = ({
  data,
  isLoading,
  sectionType
}: IProps) => {
  const { selectedClient } = useClientStore((state) => state);

  const campaignResponse = data ?? [];

  const impressionShareReportData = useMemo(() => {
    const tableHeader = [
      {
        id: 'Campaign',
        header: (props) => {
          const headerId = props?.table?.options?.state?.sorting[0]?.id;
          const sortDirection = props?.table?.options?.state?.sorting[0]?.desc;
          if (headerId === 'Campaign' && sortDirection === false) {
            return (
              <div className="flex justify-start items-center gap-2 font-inter w-[200px] sm:w-[25vw] h-fit pl-4">
                <div>Campaign</div>
                <Icon name="ChevronUp" color="#808DA1" size={20} />
              </div>
            );
          }

          if (headerId === 'Campaign' && sortDirection === true) {
            return (
              <div className="flex justify-start items-center gap-2 font-inter w-[200px] sm:w-[25vw] h-fit pl-4">
                <div>Campaign</div>
                <Icon name="ChevronDown" color="#808DA1" size={20} />
              </div>
            );
          }

          if (headerId !== 'Campaign' || headerId === 'Campaign') {
            return (
              <div className="flex justify-start items-center gap-2 font-inter w-[200px] sm:w-[25vw] h-fit pl-4">
                <div>Campaign</div>
                <Icon name="Sort" />
              </div>
            );
          }
        },
        accessorKey: 'name',
        cell: (props: any) => (
          <div className="flex justify-start font-inter w-[200px] sm:w-[25vw] pl-4 h-fit">
            <div className="truncate w-[180px] sm:w-[25vw] overflow-hidden">
              {props.getValue()}
            </div>
          </div>
        ),
      },
      {
        id: 'Cost',
        header: (props) => {
          const headerId = props?.table?.options?.state?.sorting[0]?.id;
          const sortDirection = props?.table?.options?.state?.sorting[0]?.desc;
          if (headerId === 'Cost' && sortDirection === false) {
            return (
              <div className="flex justify-center items-center gap-2 font-inter w-[200px] sm:w-[14vw] h-fit pl-4">
                <div>Cost</div>
                <Icon name="ChevronUp" color="#808DA1" size={20} />
              </div>
            );
          }

          if (headerId === 'Cost' && sortDirection === true) {
            return (
              <div className="flex justify-center items-center gap-2 font-inter w-[200px] sm:w-[14vw] h-fit pl-4">
                <div>Cost</div>
                <Icon name="ChevronDown" color="#808DA1" size={20} />
              </div>
            );
          }

          if (headerId !== 'Cost' || headerId === 'Cost') {
            return (
              <div className="flex justify-center items-center gap-2 font-inter w-[200px] sm:w-[14vw] h-fit pl-4">
                <div>Cost</div>
                <Icon name="Sort" />
              </div>
            );
          }
        },
        accessorKey: 'cost',
        cell: (props: any) => (
          <div className="flex justify-center font-inter w-[200px] sm:w-[14vw] pl-4 h-fit">
            {formatNumber(props.getValue(), false)}
          </div>
        ),
      },
      {
        id: 'Impression Share (%)',
        header: (props) => {
          const headerId = props?.table?.options?.state?.sorting[0]?.id;
          const sortDirection = props?.table?.options?.state?.sorting[0]?.desc;
          if (headerId === 'Impression Share (%)' && sortDirection === false) {
            return (
              <div className="flex justify-center items-center gap-2 font-inter w-[200px] sm:w-[14vw] h-fit pl-4">
                <div>Impression Share (%)</div>
                <Icon name="ChevronUp" color="#808DA1" size={20} />
              </div>
            );
          }

          if (headerId === 'Impression Share (%)' && sortDirection === true) {
            return (
              <div className="flex justify-center items-center gap-2 font-inter w-[200px] sm:w-[14vw] h-fit pl-4">
                <div>Impression Share (%)</div>
                <Icon name="ChevronDown" color="#808DA1" size={20} />
              </div>
            );
          }

          if (
            headerId !== 'Impression Share (%)' ||
            headerId === 'Impression Share (%)'
          ) {
            return (
              <div className="flex justify-center items-center gap-2 font-inter w-[200px] sm:w-[14vw] h-fit pl-4">
                <div>Impression Share (%)</div>
                <Icon name="Sort" />
              </div>
            );
          }
        },
        accessorKey: 'content_impression_share',
        cell: (props: any) => (
          <div className="flex justify-center font-inter w-[200px] sm:w-[14vw] pl-4 h-fit">
            {formatNumber(props.getValue(), true)}
          </div>
        ),
      },
      {
        id: 'Impressions Lost to Ad Rank (%)',
        header: (props) => {
          const headerId = props?.table?.options?.state?.sorting[0]?.id;
          const sortDirection = props?.table?.options?.state?.sorting[0]?.desc;
          if (
            headerId === 'Impressions Lost to Ad Rank (%)' &&
            sortDirection === false
          ) {
            return (
              <div className="flex justify-center items-center gap-2 font-inter w-[250px] sm:w-[18vw] h-fit">
                <div>Impressions Lost to Ad Rank (%)</div>
                <Icon name="ChevronUp" color="#808DA1" size={20} />
              </div>
            );
          }

          if (
            headerId === 'Impressions Lost to Ad Rank (%)' &&
            sortDirection === true
          ) {
            return (
              <div className="flex justify-center items-center gap-2 font-inter w-[250px] sm:w-[18vw] h-fit">
                <div>Impressions Lost to Ad Rank (%)</div>
                <Icon name="ChevronDown" color="#808DA1" size={20} />
              </div>
            );
          }

          if (
            headerId !== 'Impressions Lost to Ad Rank (%)' ||
            headerId === 'Impressions Lost to Ad Rank (%)'
          ) {
            return (
              <div className="flex justify-center items-center gap-2 font-inter w-[250px] sm:w-[18vw] h-fit">
                <div>Impressions Lost to Ad Rank (%)</div>
                <Icon name="Sort" />
              </div>
            );
          }
        },
        accessorKey: 'content_rank_lost',
        cell: (props: any) => (
          <div className="flex justify-center font-inter w-[250px] sm:w-[18vw] pl-4 h-fit">
            {formatNumber(props.getValue(), true)}
          </div>
        ),
      },
      {
        id: 'Impressions Lost to Budget (%)',
        header: (props) => {
          const headerId = props?.table?.options?.state?.sorting[0]?.id;
          const sortDirection = props?.table?.options?.state?.sorting[0]?.desc;
          if (
            headerId === 'Impressions Lost to Budget (%)' &&
            sortDirection === false
          ) {
            return (
              <div className="flex justify-center items-center gap-2 font-inter w-[250px] sm:w-[18vw] h-fit pl-4">
                <div>Impressions Lost to Budget (%)</div>
                <Icon name="ChevronUp" color="#808DA1" size={20} />
              </div>
            );
          }

          if (
            headerId === 'Impressions Lost to Budget (%)' &&
            sortDirection === true
          ) {
            return (
              <div className="flex justify-center items-center gap-2 font-inter w-[250px] sm:w-[18vw] h-fit pl-4">
                <div>Impressions Lost to Budget (%)</div>
                <Icon name="ChevronDown" color="#808DA1" size={20} />
              </div>
            );
          }

          if (
            headerId !== 'Impressions Lost to Budget (%)' ||
            headerId === 'Impressions Lost to Budget (%)'
          ) {
            return (
              <div className="flex justify-center items-center gap-2 font-inter w-[250px] sm:w-[18vw] h-fit pl-4">
                <div>Impressions Lost to Budget (%)</div>
                <Icon name="Sort" />
              </div>
            );
          }
        },
        accessorKey: 'content_budget_lost',
        cell: (props: any) => (
          <div className="flex justify-center font-inter w-[250px] sm:w-[18vw] pl-4 h-fit">
            {formatNumber(props.getValue(), true)}
          </div>
        ),
      },
    ];

    let loading = true;
    const data = Array.isArray(campaignResponse) && (campaignResponse || []).map((resource: any) => {
      return {
        name: resource?.campaign_name,
        cost: resource?.cost,
        content_impression_share: Number(resource?.search_impression_share),
        content_budget_lost: Number(
          resource?.search_budget_lost_impression_share
        ),
        content_rank_lost: Number(resource?.search_rank_lost_impression_share),
      };
    });

    if (campaignResponse) {
      loading = false;
    }

    return { tableHeader, data, loading };
  }, [campaignResponse]);

  const campaignsReportData = useMemo(() => {
    const tableHeader = [
      {
        id: 'Campaign Name',
        header: (props) => {
          const headerId = props?.table?.options?.state?.sorting[0]?.id;
          const sortDirection = props?.table?.options?.state?.sorting[0]?.desc;
          if (headerId === 'Campaign Name' && sortDirection === false) {
            return (
              <div className="flex justify-start items-center gap-2 font-inter w-[180px] sm:w-[13vw] h-fit pl-4">
                <div>Campaign Name</div>
                <Icon name="ChevronUp" color="#808DA1" size={20} />
              </div>
            );
          }

          if (headerId === 'Campaign Name' && sortDirection === true) {
            return (
              <div className="flex justify-start items-center gap-2 font-inter w-[180px] sm:w-[13vw] h-fit pl-4">
                <div>Campaign Name</div>
                <Icon name="ChevronDown" color="#808DA1" size={20} />
              </div>
            );
          }

          if (headerId !== 'Campaign Name' || headerId === 'Campaign Name') {
            return (
              <div className="flex justify-start items-center gap-2 font-inter w-[180px] sm:w-[13vw] h-fit pl-4">
                <div>Campaign Name</div>
                <Icon name="Sort" />
              </div>
            );
          }
        },
        accessorKey: 'name',
        cell: (props: any) => (
          <div className="flex justify-start font-inter w-[180px] sm:w-[13vw] pl-4 h-fit">
            <Tooltip content={props.getValue()} position="right">
              <div className="truncate w-[150px] sm:max-w-[13vw] overflow-hidden">
                {props.getValue()}
              </div>
            </Tooltip>
          </div>
        ),
      },
      {
        id: 'Cost',
        header: (props) => {
          const headerId = props?.table?.options?.state?.sorting[0]?.id;
          const sortDirection = props?.table?.options?.state?.sorting[0]?.desc;
          if (headerId === 'Cost' && sortDirection === false) {
            return (
              <div className="flex justify-center items-center gap-2 font-inter w-[150px] sm:w-[120px] sm:w-[8vw] h-fit">
                <div>Cost</div>
                <Icon name="ChevronUp" color="#808DA1" size={20} />
              </div>
            );
          }

          if (headerId === 'Cost' && sortDirection === true) {
            return (
              <div className="flex justify-center items-center gap-2 font-inter w-[150px] sm:w-[120px] sm:w-[8vw] h-fit">
                <div>Cost</div>
                <Icon name="ChevronDown" color="#808DA1" size={20} />
              </div>
            );
          }

          if (headerId !== 'Cost' || headerId === 'Cost') {
            return (
              <div className="flex justify-center items-center gap-2 font-inter w-[150px] sm:w-[8vw] h-fit">
                <div>Cost</div>
                <Icon name="Sort" />
              </div>
            );
          }
        },
        accessorKey: 'cost',
        cell: (props: any) => (
          <div className="flex justify-center font-inter w-[150px] sm:w-[8vw] h-fit">
            {formatToCurrency(props.getValue())}
          </div>
        ),
      },

      {
        id: 'Impression',
        header: (props) => {
          const headerId = props?.table?.options?.state?.sorting[0]?.id;
          const sortDirection = props?.table?.options?.state?.sorting[0]?.desc;
          if (headerId === 'Impression' && sortDirection === false) {
            return (
              <div className="flex justify-center items-center gap-2 font-inter w-[150px] sm:w-[8vw] h-fit">
                <div>Impression</div>
                <Icon name="ChevronUp" color="#808DA1" size={20} />
              </div>
            );
          }

          if (headerId === 'Impression' && sortDirection === true) {
            return (
              <div className="flex justify-center items-center gap-2 font-inter w-[150px] sm:w-[8vw] h-fit">
                <div>Impression</div>
                <Icon name="ChevronDown" color="#808DA1" size={20} />
              </div>
            );
          }

          if (headerId !== 'Impression' || headerId === 'Impression') {
            return (
              <div className="flex justify-center items-center gap-2 font-inter w-[150px] sm:w-[8vw] h-fit">
                <div>Impression</div>
                <Icon name="Sort" />
              </div>
            );
          }
        },
        accessorKey: 'impressions',
        cell: (props: any) => (
          <div className="flex justify-center font-inter w-[150px] sm:w-[8vw] h-fit">
            {formatNumber(props.getValue())}
          </div>
        ),
      },
      {
        id: 'Clicks',
        header: (props) => {
          const headerId = props?.table?.options?.state?.sorting[0]?.id;
          const sortDirection = props?.table?.options?.state?.sorting[0]?.desc;
          if (headerId === 'Clicks' && sortDirection === false) {
            return (
              <div className="flex justify-center items-center gap-2 font-inter w-[150px] sm:w-[5vw] h-fit">
                <div>Clicks</div>
                <Icon name="ChevronUp" color="#808DA1" size={20} />
              </div>
            );
          }

          if (headerId === 'Clicks' && sortDirection === true) {
            return (
              <div className="flex justify-center items-center gap-2 font-inter w-[150px] sm:w-[5vw] h-fit">
                <div>Clicks</div>
                <Icon name="ChevronDown" color="#808DA1" size={20} />
              </div>
            );
          }

          if (headerId !== 'Clicks' || headerId === 'Clicks') {
            return (
              <div className="flex justify-center items-center gap-2 font-inter w-[150px] sm:w-[5vw] h-fit">
                <div>Clicks</div>
                <Icon name="Sort" />
              </div>
            );
          }
        },
        accessorKey: 'clicks',
        cell: (props: any) => (
          <div className="flex justify-center font-inter w-[150px] sm:w-[5vw] h-fit">
            {formatNumber(props.getValue())}
          </div>
        ),
      },
      {
        id: 'CPC',
        header: (props) => {
          const headerId = props?.table?.options?.state?.sorting[0]?.id;
          const sortDirection = props?.table?.options?.state?.sorting[0]?.desc;
          if (headerId === 'CPC' && sortDirection === false) {
            return (
              <div className="flex justify-center items-center gap-2 font-inter w-[150px] sm:w-[5vw] h-fit">
                <div>CPC</div>
                <Icon name="ChevronUp" color="#808DA1" size={20} />
              </div>
            );
          }

          if (headerId === 'CPC' && sortDirection === true) {
            return (
              <div className="flex justify-center items-center gap-2 font-inter w-[150px] sm:w-[5vw] h-fit">
                <div>CPC</div>
                <Icon name="ChevronDown" color="#808DA1" size={20} />
              </div>
            );
          }

          if (headerId !== 'CPC' || headerId === 'CPC') {
            return (
              <div className="flex justify-center items-center gap-2 font-inter w-[150px] sm:w-[5vw] h-fit">
                <div>CPC</div>
                <Icon name="Sort" />
              </div>
            );
          }
        },
        accessorKey: 'cpc',
        cell: (props: any) => (
          <div className="flex justify-center font-inter w-[150px] sm:w-[5vw] h-fit">
            {formatToCurrency(props.getValue())}
          </div>
        ),
      },
      {
        id: 'Conversions',
        header: (props) => {
          const headerId = props?.table?.options?.state?.sorting[0]?.id;
          const sortDirection = props?.table?.options?.state?.sorting[0]?.desc;
          if (headerId === 'Conversions' && sortDirection === false) {
            return (
              <div className="flex justify-center items-center gap-2 font-inter w-[150px] sm:w-[8vw] h-fit">
                <div>Conversions</div>
                <Icon name="ChevronUp" color="#808DA1" size={20} />
              </div>
            );
          }

          if (headerId === 'Conversions' && sortDirection === true) {
            return (
              <div className="flex justify-center items-center gap-2 font-inter w-[150px] sm:w-[8vw] h-fit">
                <div>Conversions</div>
                <Icon name="ChevronDown" color="#808DA1" size={20} />
              </div>
            );
          }

          if (headerId !== 'Conversions' || headerId === 'Conversions') {
            return (
              <div className="flex justify-center items-center gap-2 font-inter w-[150px] sm:w-[8vw] h-fit">
                <div>Conversions</div>
                <Icon name="Sort" />
              </div>
            );
          }
        },
        accessorKey: 'conversions',
        cell: (props: any) => (
          <div className="flex justify-center font-inter w-[150px] sm:w-[8vw] h-fit">
            {formatNumber(props.getValue())}
          </div>
        ),
      },
      {
        id: 'Conversions Rate',
        header: (props) => {
          const headerId = props?.table?.options?.state?.sorting[0]?.id;
          const sortDirection = props?.table?.options?.state?.sorting[0]?.desc;
          if (headerId === 'Conversions Rate' && sortDirection === false) {
            return (
              <div className="flex justify-center items-center gap-2 font-inter w-[180px] sm:w-[10vw] h-fit">
                <div>Conversions Rate</div>
                <Icon name="ChevronUp" color="#808DA1" size={20} />
              </div>
            );
          }

          if (headerId === 'Conversions Rate' && sortDirection === true) {
            return (
              <div className="flex justify-center items-center gap-2 font-inter w-[180px] sm:w-[10vw] h-fit">
                <div>Conversions Rate</div>
                <Icon name="ChevronDown" color="#808DA1" size={20} />
              </div>
            );
          }

          if (
            headerId !== 'Conversions Rate' ||
            headerId === 'Conversions Rate'
          ) {
            return (
              <div className="flex justify-center items-center gap-2 font-inter w-[180px] sm:w-[10vw] h-fit">
                <div>Conversions Rate</div>
                <Icon name="Sort" />
              </div>
            );
          }
        },
        accessorKey: 'conversion_rate',
        cell: (props: any) => (
          <div className="flex justify-center font-inter w-[180px] sm:w-[10vw] h-fit">
            {formatNumber(props.getValue())} %
          </div>
        ),
      },
      {
        id: 'CTR',
        header: (props) => {
          const headerId = props?.table?.options?.state?.sorting[0]?.id;
          const sortDirection = props?.table?.options?.state?.sorting[0]?.desc;
          if (headerId === 'CTR' && sortDirection === false) {
            return (
              <div className="flex justify-center items-center gap-2 font-inter w-[120px] sm:w-[5vw] h-fit">
                <div>CTR</div>
                <Icon name="ChevronUp" color="#808DA1" size={20} />
              </div>
            );
          }

          if (headerId === 'CTR' && sortDirection === true) {
            return (
              <div className="flex justify-center items-center gap-2 font-inter w-[120px] sm:w-[5vw] h-fit">
                <div>CTR</div>
                <Icon name="ChevronDown" color="#808DA1" size={20} />
              </div>
            );
          }

          if (headerId !== 'CTR' || headerId === 'CTR') {
            return (
              <div className="flex justify-center items-center gap-2 font-inter w-[120px] sm:w-[5vw] h-fit">
                <div>CTR</div>
                <Icon name="Sort" />
              </div>
            );
          }
        },
        accessorKey: 'ctr',
        cell: (props: any) => (
          <div className="flex justify-center font-inter w-[120px] sm:w-[5vw] h-fit">
            {formatNumber(props.getValue())} %
          </div>
        ),
      },
      {
        id: 'ROAS',
        header: (props) => {
          const headerId = props?.table?.options?.state?.sorting[0]?.id;
          const sortDirection = props?.table?.options?.state?.sorting[0]?.desc;
          if (headerId === 'ROAS' && sortDirection === false) {
            return (
              <div className="flex justify-center items-center gap-2 font-inter w-[150px] sm:w-[5vw] h-fit">
                <div>ROAS</div>
                <Icon name="ChevronUp" color="#808DA1" size={20} />
              </div>
            );
          }

          if (headerId === 'ROAS' && sortDirection === true) {
            return (
              <div className="flex justify-center items-center gap-2 font-inter w-[150px] sm:w-[5vw] h-fit">
                <div>ROAS</div>
                <Icon name="ChevronDown" color="#808DA1" size={20} />
              </div>
            );
          }

          if (headerId !== 'ROAS' || headerId === 'ROAS') {
            return (
              <div className="flex justify-center items-center gap-2 font-inter w-[150px] sm:w-[5vw] h-fit">
                <div>ROAS</div>
                <Icon name="Sort" />
              </div>
            );
          }
        },
        accessorKey: 'roas',
        cell: (props: any) => (
          <div className="flex justify-center font-inter w-[150px] sm:w-[5vw] h-fit">
            {formatNumber(props.getValue())}
          </div>
        ),
      },
    ];

    let loading = true;

    const data = Array.isArray(campaignResponse) && campaignResponse?.map((resource: any) => ({
      name: resource.campaign_name,
      clicks: Number(resource?.clicks),
      cost: Number(resource?.cost),
      conversions: Number(resource?.conversions),
      conversion_rate: Number(resource?.conversion_rate),
      impressions: Number(resource?.impressions),
      cpc: Number(resource?.cpc),
      roas: Number(resource?.roas),
      ctr: Number(resource?.ctr) || 0,
    }));

    // filteredCampaigns
    if (campaignResponse) {
      loading = false;
    }

    return { tableHeader, data, loading };
    // filteredCampaigns
  }, [campaignResponse]);

  return (
    <div className="relative">
      <AnalyticsTable
          data={sectionType === "Impression Share Overview" ? impressionShareReportData?.data : campaignsReportData.data}
          columns={sectionType === "Impression Share Overview" ? impressionShareReportData?.tableHeader : campaignsReportData?.tableHeader}
          totalDocs={sectionType === "Impression Share Overview" 
            ? impressionShareReportData?.data?.length
            : campaignsReportData?.data?.length
          }
          sortByColumnId={sectionType === "Impression Share Overview" ? "Impression Share (%)" : "Organic Traffic"}
          loading={isLoading}
          pagination={true}
          selectedClient={selectedClient}
          highlightRow={true}
        />
    </div>
  )
}

export default CampaignAndImpressionShareOverview;