import { FC, ChangeEvent, useState } from 'react';
import { RiMailAddFill } from 'react-icons/ri';
import Icon from '../../../assets/icons/SvgComponent';
import { useThemeStore } from '../../../store/useThemeStore';

interface ReportForm {
  report_type?: string;
  report?: {
    dashthis_url?: string;
    outreach_url?: string;
    rank_ranger_url?: string;
    strategy?: string;
  };
  recipients?: string[];
}

interface SettingsProps {
  setReportForm: (form: ReportForm) => void;
  reportForm: ReportForm;
  clientReport?: any; // Not sure about the exact structure of clientReport
}

const Settings: FC<SettingsProps> = ({
  setReportForm,
  reportForm,
  clientReport,
}) => {
  const { mode } = useThemeStore((state: any) => state);
  const iconColor = mode === 'dark' ? 'white' : 'black';
  const [email, setEmail] = useState<string>('');
  const [error, setError] = useState<boolean>(false);
  const isSeo = reportForm?.report_type?.includes('seo');
  const dashthisURL =
    reportForm?.report?.dashthis_url ||
    clientReport?.[`${clientReport?.report_type}_report_config`]?.dashthis_url;
  const outReachUrl =
    reportForm?.report?.outreach_url ||
    clientReport?.[`${clientReport?.report_type}_report_config`]?.outreach_url;
  const rankrangerURL =
    reportForm?.report?.rank_ranger_url ||
    clientReport?.[`${clientReport?.report_type}_report_config`]
      ?.rank_ranger_url;
  const strategyURL =
    reportForm?.report?.strategy ||
    clientReport?.[`${clientReport?.report_type}_report_config`]?.strategy;

  const isValidEmail = (email: string): boolean => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleDeleteRecipient = (email: string): void => {
    const filteredReci = reportForm?.recipients?.filter(
      (recipient) => recipient !== email
    );
    setError(false);
    setReportForm({
      ...reportForm,
      recipients: filteredReci,
    });
  };

  const handleAddRecipient = (): void => {
    if (reportForm?.recipients?.includes(email)) {
      setError(true);
      return;
    } else if (isValidEmail(email)) {
      setReportForm({
        ...reportForm,
        recipients: [email, ...(reportForm?.recipients || [])],
      });
      setEmail('');
      setError(false);
    }
  };

  const handleLinks = (e: ChangeEvent<HTMLInputElement>): void => {
    setReportForm({
      ...reportForm,
      report: {
        ...reportForm.report,
        [e.target.name]: e.target.value,
      },
    });
  };

  // ... (rest of the component)

  return (
    <div>
      <div style={{ width: '100%', display: 'flex' }}></div>
      <form
        style={{
          display: 'flex',
          justifyContent: 'center',
          flexDirection: 'column',
          position: 'relative',
          width: '100%',
        }}
        className="border-b dark:border-b-[#fff] pb-4"
        onSubmit={handleAddRecipient}
      >
        <div className="mb-4">
          <span className="font-[600] dark:text-white my-4">Recipients</span>
          <input
            className="block w-full p-2 ps-2 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 mt-4"
            type="email"
            name="email"
            value={email}
            placeholder="Enter recipient's email address"
            onChange={(e) => setEmail(e.target.value)}
          />
          <div
            className={
              isValidEmail(email)
                ? `absolute right-0 top-0 cursor-pointer bg-[#005C9F] py-[5px] px-[20px] rounded-[5px] z-[99]`
                : 'absolute right-0 top-0 cursor-pointer bg-[#B5CEE9] py-[5px] px-[20px] rounded-[5px] z-[99]'
            }
            onClick={() => isValidEmail(email) && handleAddRecipient()}
          >
            <RiMailAddFill size={20} color="#fff" />
          </div>
        </div>

        <div className="border border-[#000] dark:border-[#fff] rounded-lg flex flex-wrap gap-2 px-4 py-2 h-[100px] overflow-auto scrollbar-thin scrollbar-thumb-gray-300 scrollbar-track-gray-100 scrollbar-rounded-8">
          {reportForm.recipients?.map((recipient) => (
            <div
              className="flex items-center gap-2 p-[6px] border border-[#000] dark:border-[#fff] text-xs font-semibold rounded-lg h-fit dark:text-gray-400"
              key={recipient}
            >
              <p>{recipient} </p>
              <div
                className="cursor-pointer"
                onClick={() => handleDeleteRecipient(recipient)}
              >
                <Icon
                  name="Xclose"
                  size={8}
                  color={mode === 'dark' ? '#fff' : iconColor}
                />
              </div>
            </div>
          ))}
        </div>

        {error && (
          <div className="text-[#FF0000] font-[600] text-sm uppercase my-[20px] ">
            Duplicated Email
          </div>
        )}
      </form>
      <form className="flex items-start flex-col">
        <div className="w-full flex items-start flex-col pb-4 border-b dark:border-b-[#fff]">
          <div className="flex justify-between items-center w-full">
            <div className="font-[600] my-[10px] dark:text-[#fff]">
              Dashboard
            </div>
            <a href={dashthisURL} target="blank">
              <Icon
                name="ArrowRight"
                color={mode === 'dark' ? '#fff' : iconColor}
              />
            </a>
          </div>
          <input
            className="block w-full p-2 ps-2 text-sm text-gray-900 border-none rounded-lg  focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-800  dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            onChange={handleLinks}
            type="text"
            name="dashthis_url"
            value={dashthisURL}
          />
        </div>
        {isSeo ? (
          <div className="w-full flex items-start flex-col pb-4 border-b dark:border-b-[#fff]">
            <div className="flex justify-between items-center w-full">
              <div className="font-[600] my-[10px] dark:text-[#fff]">
                Rank Ranger
              </div>
              <a href={rankrangerURL} target="blank">
                <Icon
                  name="ArrowRight"
                  color={mode === 'dark' ? '#fff' : iconColor}
                />
              </a>
            </div>
            <input
              className="block w-full p-2 ps-2 text-sm text-gray-900 border-none rounded-lg  focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-800  dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              onChange={handleLinks}
              type="text"
              name="rank_ranger_url"
              value={rankrangerURL}
            />
          </div>
        ) : null}
        {isSeo ? (
          <div className="w-full flex items-start flex-col pb-4 border-b dark:border-b-[#fff]">
            <div className="flex justify-between items-center w-full">
              <div className="font-[600] my-[10px] dark:text-[#fff]">
                Strategy
              </div>
              <a href={strategyURL} target="blank">
                <Icon
                  name="ArrowRight"
                  color={mode === 'dark' ? '#fff' : iconColor}
                />
              </a>
            </div>
            <input
              className="block w-full p-2 ps-2 text-sm text-gray-900 border-none rounded-lg  focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-800  dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              onChange={handleLinks}
              type="text"
              name="strategy"
              value={strategyURL}
            />
          </div>
        ) : null}

        {isSeo ? (
          <div className="w-full flex items-start flex-col border-b pb-2 dark:border-b-[#fff]">
            <div className="flex justify-between items-center w-full">
              <div className="font-[600] my-[10px] dark:text-[#fff]">
                Outreach
              </div>
              <a href={outReachUrl} target="blank">
                <Icon
                  name="ArrowRight"
                  color={mode === 'dark' ? '#fff' : iconColor}
                />
              </a>
            </div>
            <input
              className="block w-full p-2 ps-2 text-sm text-gray-900 border-none rounded-lg  focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-800  dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              onChange={handleLinks}
              type="text"
              name="outreach_url"
              value={outReachUrl}
            />
          </div>
        ) : null}
      </form>
    </div>
  );
};

export default Settings;
