import moment from "moment";
import { v4 as uuidv4 } from 'uuid';

export const getStatusMetric = (status) => {
  switch (status) {
    case 'organic_sessions':
      return 'Organic Sessions';
    case 'organic_conversions':
      return 'Organic Conversions';
    case 'organic_totalRevenue':
      return 'Organic Total Revenue';
    case 'totalRevenue':
      return 'Total Revenue';
    case 'transactions':
      return 'Transactions';
    case 'sessions':
      return 'Sessions';
    case 'engagedSessions':
      return 'Engaged Sessions';
    case 'organic_engagedSessions':
      return 'Organic Engaged Sessions';
    case 'newUsers':
      return 'New Users';
    case 'organic_newUsers':
      return 'Organic New Users';
    case 'sessionConversionRate':
      return 'Session Conversion Rate';
    case 'landingPage_sessions':
      return 'Landing Page (All Channels) - Sessions';
    case 'landingPage_conversions':
      return 'Landing Page (All Channels) - Conversions';
    case 'landingPage_organic_sessions':
      return 'Organic Landing Page - Sessions';
    case 'landingPage_organic_conversions':
      return 'Organic Landing Page - Conversions';
    case 'clicks':
      return 'Clicks';
    case 'conversion_rate':
      return 'Conversion Rate';
    case 'conversions':
      return 'Conversions';
    case 'conversions_value':
      return 'Conversions Value';
    case 'cost':
      return 'Cost';
    case 'cpc':
      return 'Cpc';
    case 'ctr':
      return 'Ctr';
    case 'impressions':
      return 'Impressions';
    case 'roas':
      return 'Return on Ad Spend';
    case 'Position':
      return 'Position';
    case 'spend':
      return 'Ad Spend';
    case 'revenue':
      return 'Revenue';
    case 'reach':
      return 'Reach';
    case 'link_clicks':
      return 'Link Clicks';
    case 'website_purchases':
      return 'Purchases';
    case 'aov':
      return 'Average Order Value (AOV)';
    case 'cps':
      return 'Cost Per Sale (CPS)';
    case 'refdomains':
      return 'Refdomains';
    case 'domain_rating':
      return 'Domain Rating';
    case 'Adwords Traffic':
      return 'Estimated Traffic';
    case 'Organic Traffic':
      return 'Estimated Organic Traffic Value';
    case 'Spend':
      return 'Cost';
    case 'conversionRate':
      return 'Conversion Rate';
    case 'conversionsValue':
      return 'Conversions Value';
    case 'ReturnOnAdSpend':
      return 'Return on Ad Spend';
    case 'costPerConversion':
      return 'Cost Per Conversion';
    case 'organic_sessionConversionRate':
      return 'Organic Session Conversion Rate';
    case 'totalRoas':
      return 'Total Roas';
    default:
      return capitalized(status);
  }
};

export const capitalized = (str) => {
  return str.charAt(0).toUpperCase() + str.slice(1);
};

export const metrics = [
  {
    label: 'Organic Sessions',
    value: 'organic_sessions',
  },
  {
    label: 'Organic Conversions',
    value: 'organic_conversions',
  },
  {
    label: 'Organic Revenue',
    value: 'organic_totalRevenue',
  },
  {
    label: 'Total Revenue',
    value: 'totalRevenue',
  },
  {
    label: 'Clicks',
    value: 'clicks',
  },
  {
    label: 'Impressions',
    value: 'impressions',
  },
];

export const metricSourceOptionsPerService = {
  "seo": [
    {
      label: 'Ahrefs',
      value: 'ahrefs',
    },
    {
      label: 'GA4',
      value: 'ga4',
    },
    {
      label: 'GSC',
      value: 'gsc',
    },
    {
      label: 'Semrush',
      value: 'semrush',
    },
    {
      label: 'Rank Ranger',
      value: 'rankRanger',
    },
  ],
  "google_ads": [
    {
      label: 'Google Ads',
      value: 'googleAds',
    },
  ],
  "microsoft_ads": [],
  "meta": []
}

export const metricSourceOptions = [
  {
    label: 'Ahrefs',
    value: 'ahrefs',
  },
  {
    label: 'GA4',
    value: 'ga4',
  },
  {
    label: 'GSC',
    value: 'gsc',
  },
  {
    label: 'Google ADs',
    value: 'googleAds'
  },
  // {
  //   label: 'Meta',
  //   value: 'meta',
  // },
  // {
  //   label: 'Microsoft Ads',
  //   value: 'bing',
  // },
  {
    label: 'Semrush',
    value: 'semrush',
  },
  {
    label: 'Rank Ranger',
    value: 'rankRanger',
  },
];

// Utility function to get dependent options with label and value
export const getMetricSourceOptions = (selectedSource: string) => {
  const dependentOptions = {
    ahrefs: [
      {
        label: 'Organic Keywords Trend', 
        value: 'top_keywords_history',
        tooltip: "Trends in ranking and usage of specific keywords in organic search over time."
      },
      { 
        label: 'Domain Referring', 
        value: 'refdomains',
        tooltip: "Domains that direct traffic to your site through links."
      },
      { 
        label: 'Domain Rating', 
        value: 'domain_rating',
        tooltip: "A score that represents the strength of a website's backlink profile."
      },
      {
        label: 'Discovered Backlinks',
        value: 'backlinks',
        tooltip: "Discovered Backlinks"
      }
    ],
    ga4: [
      { 
        label: 'Organic Sessions', 
        value: 'organic_sessions',
        tooltip: "The number of visits to your site from organic search results." },
      { 
        label: 'Organic Conversions', 
        value: 'organic_conversions',
        tooltip: "The number of conversions that originated from organic search traffic. This metric helps you measure the effectiveness of your SEO efforts in driving conversions." },
      { 
        label: 'Organic Revenue', 
        value: 'organic_totalRevenue',
        tooltip: "Total revenue accrued from all organic sessions." },
      { 
        label: 'Total Revenue', 
        value: 'totalRevenue',
        tooltip: "The total monetary value generated from all conversions and transactions on your website. It represents the overall financial performance of your website." },
      { 
        label: 'Transactions', 
        value: 'transactions',
        tooltip: "The total number of completed transactions or purchases on your website. It is often used in e-commerce tracking to measure sales performance." 
      },
      { 
        label: 'Sessions (All Channels)', 
        value: 'sessions',
        tooltip: "Total number of sessions during a specified time period on your site." 
      },
      { 
        label: 'Engaged Sessions',
        value: 'engagedSessions',
        tooltip: "Sessions where a user interacts significantly with your site." 
      },
      { 
        label: 'Organic Engaged Sessions', 
        value: 'organic_engagedSessions',
        tooltip: "Engaged sessions that originate from organic search."
      },
      { 
        label: 'New users', 
        value: 'newUsers',
        tooltip: "First-time visitors to your site during a specified timeframe." 
      },
      { 
        label: 'New Users (Organic)', 
        value: 'organic_newUsers',
        tooltip: "The number of users who visited your website for the first time during the specified time period, and originated from organic search traffic." 
      },
      { 
        label: 'Session Conversion Rate', 
        value: 'sessionConversionRate',
        tooltip: "The percentage of all sessions that result in a conversion." 
      },
      {
        label: 'Traffic by Channel', 
        value: 'top_traffic_sources',
        tooltip: "Traffic by Channel" 
      },
      {
        label: 'Session By Channel', 
        value: 'new_users',
        tooltip: "Session By Channel" 
      },
      {
        label: 'Landing Page (All Channels)',
        value: 'landingPage',
        tooltop: "Landing Page (All Channels)",
      },
      // {
      //   label: 'Landing Page (All Channels) - Conversions',
      //   value: 'landingPage_conversions',
      // },
      {
        label: 'Organic Landing Page - Sessions',
        value: 'organic_landingPage',
        tooltop: "Organic Landing Page - Sessions",
      },
      // {
      //   label: 'Organic Landing Page - Conversions',
      //   value: 'landingPage_organic_conversions',
      //   tooltop: "",
      // },
      { 
        label: 'Total Conversions', 
        value: 'conversions', 
        tooltip: "The total number of completed goals or transactions on your website. Conversions can represent various actions, such as purchases, sign-ups, or other predefined goals." 
      },
      // { label: 'Session Conversion Rate', value: '' },
      // { label: 'Organic Conversions', value: '' },
      { 
        label: 'Organic Conversions Rate', 
        value: 'organic_sessionConversionRate',
        tooltip: "The percentage of organic sessions that convert into leads or sales."
      },
    ],
    googleAds: [
      { 
        label: 'Clicks', 
        value: 'clicks',
        tooltip: "Clicks are the number of times users clicked on your ad to visit your website or landing page." 
      },
      { 
        label: 'Conversion Rate', 
        value: 'conversionRate',
        tooltip: "Conversion Rate" 
      },
      { 
        label: 'Conversions', 
        value: 'conversions',
        tooltip: "Conversions represent the number of desired actions taken by users, such as sign-ups, purchases, or form submissions." 
      },
      { 
        label: 'Conversion Value', 
        value: 'conversionsValue',
        tooltip: "Conversions Value represents the total value generated from conversions, often in terms of revenue or other monetary metrics." 
      },
      { 
        label: 'Cost', 
        value: 'cost',
        tooltip: "Cost is the total amount spent on your advertising campaign." 
      },
      { 
        label: 'Cost Per Click', 
        value: 'cpc',
        tooltip: "Cost Per Click" 
      },
      { 
        label: 'Cost Per Conversions', 
        value: 'costPerConversion',
        tooltip: "Cost Per Conversions" 
      },
      { 
        label: 'Click-Through Rate', 
        value: 'ctr',
        tooltip: "Click-Through Rate" 
      },
      { 
        label: 'Impressions', 
        value: 'impressions',
        tooltip: "Impressions represent the number of times your ad was displayed to a user." 
      },
      { 
        label: 'Return on Ad Spend', 
        value: 'totalRoas',
        tooltip: "Return on Ad Spend" 
      },
      { 
        label: 'Campaign Overview',
        value: 'campaigns',
        tooltip: "" 
      },
      { 
        label: 'Ad Group Overview', 
        value: 'adGroups',
        tooltip: "" 
      },
      { 
        label: 'Keyword Overview', 
        value: 'keywordsView',
        tooltip: "" 
      },
      { 
        label: 'Top Performing Ads',
        value: 'adGroupAds',
        tooltip: "" 
      },
      { 
        label: 'Quality Score Overview', 
        value: 'keywordsView',
        tooltip: "" 
      },
      { 
        label: 'Impression Share Overview', 
        value: 'campaigns',
        tooltip: "" 
      },
      { 
        label: 'Devices Overview', 
        value: 'campaignDevices',
        tooltip: "" 
      }
    ],
    gsc: [
      { 
        label: 'Clicks', 
        value: 'clicks',
        tooltip: "Clicks"
      },
      { 
        label: 'Impressions', 
        value: 'impressions',
        tooltip: "Impressions"
      },
      { 
        label: 'CTR', 
        value: 'ctr',
        tooltip: "CTR"
      },
      { 
        label: 'Positions', 
        value: 'position',
        tooltip: "Positions"
      },
      { 
        label: 'Keywords', 
        value: 'queries',
        tooltip: "Keywords"
      },
      { 
        label: 'Pages', 
        value: 'pages',
        tooltip: "Pages"
      },
    ],
    // meta: [
    //   { label: 'Ad Spend', value: 'spend' },
    //   { label: 'Revenue', value: 'revenue' },
    //   { label: 'ROAS', value: 'roas' },
    //   { label: 'Impressions', value: 'impressions' },
    //   { label: 'Reach', value: 'reach' },
    //   { label: 'CPM', value: 'cpm' },
    //   { label: 'Link Clicks', value: 'link_clicks' },
    //   { label: 'CTR', value: 'ctr' },
    //   { label: 'CPC', value: 'cpc' },
    //   { label: 'Purchases', value: 'website_purchases' },
    //   { label: 'Average Order Value (AOV)', value: 'aov' },
    //   { label: 'Cost Per Sale (CPS)', value: 'cps' },
    // ],
    // bing: [
    //   { label: 'Clicks', value: 'Clicks' },
    //   { label: 'Conversion Rate', value: 'ConversionRate' },
    //   { label: 'Conversions', value: 'Conversions' },
    //   { label: 'Conversion Value', value: '' },
    //   { label: 'Cost', value: 'Spend' },
    //   { label: 'Cost Per Click', value: 'cpc' },
    //   { label: 'Cost Per Conversions', value: 'costPerConversion' },
    //   { label: 'Click-Through Rate', value: 'Ctr' },
    //   { label: 'Impressions', value: 'Impressions' },
    //   { label: 'Return on Ad Spend', value: 'ReturnOnAdSpend' },
    // ],
    semrush: [
      { 
        label: 'Total Keywords Indexed', 
        value: 'Organic Keywords' ,
        tooltip: "Specific words or phrases that users enter into search engines to find your content."
      },
      { 
        label: 'Estimated Traffic', 
        value: 'Organic Traffic' ,
        tooltip: "Traffic is a metric that provides an estimate of the monthly organic search traffic received by a website and helps gauge the potential organic search visibility of a website compared to its competitors."
      },
      { 
        label: 'Estimated Organic Traffic Value', 
        value: 'Organic Cost' ,
        tooltip: "Organic traffic value is the equivalent monthly cost of traffic from all keywords that the target website/URL ranks for organically, if that traffic was paid via PPC instead. We calculate it based on multiplying the monthly organic traffic of each keyword at its respective ranking position, by their CPC value. Then, we add up the organic traffic cost of individual keywords that the target website/URL ranks for."
      },
      { 
        label: 'Organic Ranking Position', 
        value: 'organic_search_positions',
        tooltip: "The ranking positions specifically for organic search results."
      },
      {
        label: 'Discovered Backlinks',
        value: 'backlinks',
        tooltip: "Discovered Backlinks"
      },
      {
        label: 'Competitors Insights',
        value: 'traffic_competitors',
        tooltip: "Analysis of SEO strategies and performance metrics of your competitors."
      }
    ],
    rankRanger: [
      { 
        label: 'Rankings Overview', 
        value: 'ranking_overview',
        tooltip: "Retrieving data. Wait a few seconds and try to cut or copy again."
      },
    ]
  };

  return dependentOptions[selectedSource] || [];
};

export const getMetricsTooltip = (source?: string, metric?: string) => {
  const sourceKey = getMetricSourceOptions(source);
  return metric ? sourceKey?.filter(f => f.value === metric)[0]?.tooltip : ''
}

export const getMetricSourceIcon = (key: string) => {
  const keywordMappings = {
    ahrefs: {
      label: 'Ahrefs',
      value: 'Ahref',
    },
    ga4: {
      label: 'GA4',
      value: 'googleAnalytics',
    },
    googleAds: {
      label: 'Google Ads',
      value: 'googleAd',
    },
    gsc: {
      label: 'GSC',
      value: 'googleSearch',
    },
    meta: {
      label: 'Meta',
      value: 'Meta',
    },
    bing: {
      label: 'Microsoft Ads',
      value: 'BingAnalyticsTab',
    },
    semrush: {
      label: 'Semrush',
      value: 'semrush',
    },
  };

  return (
    keywordMappings[key] || {
      label: 'Unknown',
      value: 'unknown',
    }
  );
};



export const itemsObject = (itemsArray) => {
  return itemsArray.reduce((acc, item) => {
    acc[item.key] = {...item};
    return acc;
  }, {});
}

export const emptyLayoutCharts = [
  {
    id: uuidv4(),
    key: "1",
    title: "",
    toolTip: "",
    data_config: {
      property: "",
      metrics: "",
      source: "",
    },
    display_config: {
      chartType: "",
      range: "lastYear",
      showTrendline: true,
      showIntegrationIcon: true,
      showDataLabels: true,
      themeColor: "#0029FF",
      themeStyle: "light"
    }
  },
  {
    id: uuidv4(),
    key: "2",
    title: "",
    toolTip: "",
    data_config: {
      property: "",
      metrics: "",
      source: "",
    },
    display_config: {
      chartType: "",
      range: "lastYear",
      showTrendline: true,
      showIntegrationIcon: true,
      showDataLabels: true,
      themeColor: "#0029FF",
      themeStyle: "light"
    }
  },
  {
    id: uuidv4(),
    key: "3",
    title: "",
    toolTip: "",
    report: {
      data: {},
      error: {
        status: 401,
        message: ''
      }
    },
    data_config: {
      property: "",
      metrics: "",
      source: ""
    },
    display_config: {
      chartType: "",
      range: "lastYear",
      showTrendline: true,
      showIntegrationIcon: true,
      showDataLabels: true,
      themeColor: "#0029FF",
      themeStyle: "light"
    }
  },
  {
    id: uuidv4(),
    key: "4",
    title: "",
    toolTip: "",
    report: {
      data: {},
      error: {
        status: 401,
        message: ''
      }
    },
    data_config: {
      property: "",
      metrics: "",
      source: ""
    },
    display_config: {
      chartType: "",
      range: "lastYear",
      showTrendline: true,
      showIntegrationIcon: true,
      showDataLabels: true,
      themeColor: "#0029FF",
      themeStyle: "light"
    }
  }
]

export const addChart = (key) => {
  return {
    id: uuidv4(),
    key: key.toString(),
    title: "",
    toolTip: "",
    data_config: {
      property: "",
      metrics: "",
      source: "",
    },
    display_config: {
      chartType: "",
      range: "lastYear",
      showTrendline: true,
      showIntegrationIcon: true,
      showDataLabels: true,
      themeColor: "#0029FF",
      themeStyle: "light"
    }
  }
}

export const getInitialLayout = () => {
  return {
    lg: [
      { i: "1", x: 0, y: 0, w: 3, minW: 3, h: 4 },
      { i: "2", x: 3, y: 0, w: 3, minW: 3, h: 4 },
      { i: "3", x: 6, y: 0, w: 3, minW: 3, h: 4 },
      { i: "4", x: 9, y: 0, w: 3, minW: 3, h: 4 },
    ],
    md: [
      { i: "1", x: 0, y: 0, w: 4, minW: 4, h: 4 },
      { i: "2", x: 4, y: 0, w: 4, minW: 4, h: 4 },
      { i: "3", x: 8, y: 1, w: 4, minW: 4, h: 4 },
      { i: "4", x: 0, y: 1, w: 4, minW: 4, h: 4 }
    ],
    sm: [
      { i: "1", x: 0, y: 0, w: 6, minW: 6, h: 4 },
      { i: "2", x: 6, y: 0, w: 6, minW: 6, h: 4 },
      { i: "3", x: 0, y: 1, w: 6, minW: 6, h: 4 },
      { i: "4", x: 6, y: 1, w: 6, minW: 6, h: 4 }
    ],
    xs: [
      { i: "1", x: 0, y: 0, w: 12, minW: 12, h: 4 },
      { i: "2", x: 0, y: 0, w: 12, minW: 12, h: 4 },
      { i: "3", x: 0, y: 1, w: 12, minW: 12, h: 4 },
      { i: "4", x: 0, y: 1, w: 12, minW: 12, h: 4 }
    ],
    xxs: [
      { i: "1", x: 0, y: 0, w: 12, minW: 12, h: 4 },
      { i: "2", x: 0, y: 0, w: 12, minW: 12, h: 4 },
      { i: "3", x: 0, y: 1, w: 12, minW: 12, h: 4 },
      { i: "4", x: 0, y: 1, w: 12, minW: 12, h: 4 }
    ]
  };
};

export const generateLayout = (data) => {
  return {
    lg: data?.map((item, index) => ({
      // i: item.i,
      // x: index, // Place items in rows of 3
      // y: item.y, // Math.floor(index / 3),
      // w: item.y,
      // h: 1
      ...item
    })),
    md: data?.map((item, index) => ({
      ...item
      // i: item.i,
      // x: index, // Place items in rows of 3
      // y: 0,
      // w: 1,
      // h: 1
    })),
    sm: data?.map((item, index) => ({
      ...item
      // i: item.i,
      // x: index,
      // y: 0,
      // w: 1,
      // h: 1
    })),
    xs: data?.map((item, index) => ({
      ...item
      // i: item.i,
      // x: index,
      // y: 0,
      // w: 1,
      // h: 1
    })),
    xxs: data?.map((item, index) => ({
      ...item
      // i: item.i,
      // x: index,
      // y: 0,
      // w: 1,
      // h: 1
    }))
  };
};

type LayoutItem = {
  w: number;
  h: number;
  x: number;
  y: number;
  i: string;
  moved: boolean;
  static: boolean;
};

type Layout = LayoutItem[];

export type LayoutData = {
  [breakpoint: string]: Layout;
};

export const addNewColumn = (layoutData: LayoutData, maxColumns: number, lastIndx?: number) => {
  const updatedLayoutData = {};

  for (const [breakpoint, layout] of Object.entries(layoutData)) {
    const newIndex = lastIndx ? lastIndx + 1 : layout.length + 1;
    
    // Group items by y coordinate (row)
    const rowGroups = layout.reduce((acc, item) => {
      acc[item.y] = acc[item.y] || [];
      acc[item.y].push(item);
      return acc;
    }, {});

    // Find the last row and its total width
    const rows = Object.keys(rowGroups).map(Number).sort((a, b) => b - a);
    const lastRow = rows[0] || 0;
    const lastRowItems = rowGroups[lastRow] || [];
    const lastRowWidth = lastRowItems.reduce((sum, item) => sum + item.w, 0);

    // Calculate new position
    let newX = 0;
    let newY = lastRow;

    // If last row is full (width sum = maxColumns), start a new row
    if (lastRowWidth >= maxColumns) {
      newY = lastRow + 1;
    } else {
      // If there's space in the last row, place it after the last item
      newX = lastRowWidth;
    }

    const newItem = {
      w: 3,
      h: 4,
      x: newX,
      y: newY,
      i: newIndex.toString(),
      moved: false,
      static: false
    };

    updatedLayoutData[breakpoint] = [...layout, newItem];
  }

  return updatedLayoutData;
}

export const adjustResponsiveLayouts = (layouts) => {
  const updateLayout = (layout, breakpoint) => {
    return layout.map((item) => {
      if (["md", "sm", "xs", "xxs"].includes(breakpoint)) {
        // For md, cap width at 4 unless already 12
        if (breakpoint === "md") {
          return item.w === 12 ? item : { ...item, w: 4 };
        }
        // For sm and below, force width to 12
        return { ...item, w: 12, x: 0 }; // Reset x to 0 to ensure proper stacking
      }
      return item; // lg remains unchanged
    });
  };
  return {
    lg: layouts.lg,
    md: updateLayout(layouts.md, "md"),
    sm: updateLayout(layouts.sm, "sm"),
    xs: updateLayout(layouts.xs, "xs"),
    xxs: updateLayout(layouts.xxs, "xxs"),
  };
}

export const addNewColumnTwo = (layout: any,lastIndx?: number) => {
  const MAX_COLUMNS = 12;
  const newItem: any = { w: 4, h: 4, i: lastIndx };

  // Step 1: Group items by rows
  const rows = layout.reduce((acc, item) => {
    if (!acc[item.y]) acc[item.y] = [];
    acc[item.y].push(item);
    return acc;
  }, {});

  // Step 2: Check if the second row has enough space
  const secondRow = rows[1] || [];
  const secondRowWidth = secondRow.reduce((sum, item) => sum + item.w, 0);

  if (secondRowWidth + newItem.w <= MAX_COLUMNS) {
    // Add to the second row
    newItem.x = secondRowWidth; // Place it after the last item
    newItem.y = 1;
  } else {
    // Find the next available row
    const allRows = Object.keys(rows).map(Number);
    const maxRow = Math.max(...allRows, 1); // Ensure at least one row exists
    newItem.x = 0; // Start at the beginning of the row
    newItem.y = maxRow + 1; // Place it on the next row
  }

  // Add the new item to the layout
  layout.push(newItem);

  return layout;
}

export const generateGridLayout = (timesData?: number) => {
  const times = [...Array(timesData ?? 4)];
  const widths = {lg: 3, md: 4, sm: 6, xs: 12, xxs: 12};
  return Object.keys(widths).reduce((memo, breakpoint) => {
    const width = widths[breakpoint];
    const cols = {lg: 12, md: 12, sm: 12, xs: 12, xxs: 12}[breakpoint];
    memo[breakpoint] = [
      // You can set y to 0, the collision algo will figure it out.
      ...times.map((_, i) => ({x: (i * width) % cols, y: 0, w: width, h: 4, i: String(i)}))
    ];
    return memo;
  }, {});
}


export const convertKey = (data, newKey) => {
  return data.map(({ date, SolarPanels, ...rest }) => ({
    date,
    [newKey]: SolarPanels,
    ...rest,
  }));
};

export const adjustLayout = (layout, maxColumns) => {
  return layout.map((item, index) => {
    if (index === 0) return item; // Keep the first item as is

    const previousItem = layout[index - 1];
    const newX = previousItem.x + previousItem.w;
    const newY = previousItem.y;

    // Check if adding the item would exceed the max columns
    if (newX + item.w > maxColumns) {
      return { ...item, x: 0, y: previousItem.y + previousItem.h, h: 4 };
    } else {
      return { ...item, x: newX, y: newY };
    }
  });
};

export const adjustResponsiveLayoutsTwo = (layouts) => {
  return {
    lg: layouts?.lg, // Keep lg as is
    md: adjustLayout(
      layouts?.md.map((item) => ({
        ...item,
        w: item.w === 12 ? 12 : 4, // Cap width at 4 unless already 12
      })),
      12
    ),
    sm: layouts?.sm?.length > 0 ? adjustLayout(
      layouts?.sm.map((item) => ({ ...item, w: item.w === 12 ? 12 : 6 })), // Force width to 12
      12
    ) : layouts?.sm,
    xs: layouts?.xs?.length > 0 ? adjustLayout(
      layouts?.xs.map((item) => ({ ...item, w: 12, x: 0 })),
      12
    ) : layouts?.xs,
    xxs: layouts?.xxs?.length > 0 ? adjustLayout(
      layouts?.xxs.map((item) => ({ ...item, w: 12, x: 0 })),
      12
    ) : layouts?.xs,
  };
};


export const uniqueSecond = (first, second) => {
  const validKeys = new Set(first.map(item => item.key));
  const keyToChartTypeMap = new Map(first.map(item => [item.key, item.display_config.chartType]));
  
  return Array.from(
    new Map(
      second
        .filter(item => validKeys.has(item.i))
        .map(item => {
          // Check the chartType for the current item's key
          if (keyToChartTypeMap.get(item.i) === "value") {
            // Update height to 1 if chartType is "value"
            return [item.i, { ...item, h: 1 }];
          }
          return [item.i, item];
        })
    ).values()
  );
}


export const intervals = [
  {
    label: 'Daily',
    value: 'daily',
  },
  {
    label: 'Weekly',
    value: 'weekly',
  },
  {
    label: 'Monthly',
    value: 'monthly',
  },
];


// Function to set the start date to the nearest Sunday
export const getNearestSunday = (date) => {
  const selectedDate = new Date(date);
  const dayOfWeek = selectedDate.getDay(); // 0 = Sunday, 1 = Monday, ..., 6 = Saturday

  // Calculate days to add to get to the next Sunday
  const daysToAdd = (7 - dayOfWeek) % 7;

  // If the selected date is already Sunday, return it
  if (daysToAdd === 0) {
    return selectedDate;
  }

  // Set the date to the next Sunday
  selectedDate.setDate(selectedDate.getDate() + daysToAdd);
  return selectedDate;
};