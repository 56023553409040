import { useState, useEffect } from 'react';
import ServiceNotAvailable from '../../Common/ServiceNotAvailable';
import useClientStore from '../../../store/useClientStore';
import { Card } from '@tremor/react';
import Accordion from '../../Accordion';
import useBingStore from '../../../store/useBingStore';
import MicrosoftAdsOverview from '../../AnalyticsSections/BingOverall/MicrosoftAdsOverview';
import BingCampaignOverview from '../../AnalyticsSections/BingOverall/BingCampaignOverview';
import BingAdGroupOverview from '../../AnalyticsSections/BingOverall/BingAdGroupOverview';
import BingKeywordOverview from '../../AnalyticsSections/BingOverall/BingKeywordOverview';
import BingTopPerformingAd from '../../AnalyticsSections/BingOverall/BingTopPerformingAdOverview/TopAdsReportData';
import BingQualityScoreOverview from '../../AnalyticsSections/BingOverall/BingQualityScoreOverview';
import BingDeviceOverview from '../../AnalyticsSections/BingOverall/BingDeviceOverview';

const AnalyticsBingAds = () => {
  const { selectedClient } = useClientStore((state) => state);
  const { campaignsReport } = useBingStore((state) => state);

  // table data
  const campaignResponse: any = campaignsReport?.campaigns;
  const adGroupsResponse = campaignsReport?.adGroups;
  const keywordsResponse = campaignsReport?.keywordsView;
  const topAdsResponse = campaignsReport?.adGroupAds;

  const [filteredCampaigns, setFilteredCampaigns] = useState(
    campaignResponse || []
  );
  
  useEffect(() => {
    if (campaignResponse?.length > 0 && Array.isArray(campaignResponse)) {
        setFilteredCampaigns(campaignResponse);
    }
  }, [campaignResponse]);
  // const location = useLocation();

  const isPublicDashboard = window.location.pathname === '/client/dashboard';
  const noServiceAvailable = ['activated', 'active'].includes(
    selectedClient?.bing_ads?.status
  )
    ? true
    : false;
  const noIntegrationAvailable = !['activated', 'active'].includes(
    selectedClient?.bing_ads?.AccountLifeCycleStatus?.toLowerCase()
  );

  if (!selectedClient?.loading) {
    if (isPublicDashboard && noServiceAvailable && noIntegrationAvailable) {
      return (
        <ServiceNotAvailable
          title="No Dashboard Available"
          serviceType="bing"
          hideRequestAccess={isPublicDashboard}
        />
      );
    }
    // if(!noServiceAvailable) return <ServiceNotAvailable title={isPublicDashboard ? "No Dashboard Available" : undefined}  serviceType="bing" hideRequestAccess={isPublicDashboard}/>;
    if (noIntegrationAvailable)
      return (
        <ServiceNotAvailable
          title={
            isPublicDashboard
              ? 'No Dashboard Available'
              : 'Integration Not Set Up'
          }
          serviceType="bing"
          hideRequestAccess={isPublicDashboard}
        />
      );
  }

  return (
    <div className="sm:pt-4 flex flex-col gap-2 sm:gap-4">
      {/* mobileView */}
      <Accordion
        title="Microsoft Ads"
        className="block sm:hidden dark:bg-[#222732] px-2 py-2 mt-0"
      >
        <MicrosoftAdsOverview />
      </Accordion>
      <Accordion
        title="Campaign Overview"
        className="block sm:hidden dark:bg-[#222732] px-2 py-2 mt-0"
        icon="BingAnalyticsTab"
      >
        <BingCampaignOverview filteredCampaigns={Array.isArray(filteredCampaigns) ? filteredCampaigns : []} />
      </Accordion>
      <Accordion
        title="Ad Group Overview"
        className="block sm:hidden dark:bg-[#222732] px-2 py-2 mt-0"
        icon="BingAnalyticsTab"
      >
        <BingAdGroupOverview adGroupsResponse={Array.isArray(adGroupsResponse) ? adGroupsResponse : []} />
      </Accordion>

      <Accordion
        title="Keyword Overview"
        className="block sm:hidden dark:bg-[#222732] px-2 py-2 mt-0"
        icon="BingAnalyticsTab"
      >
        <BingKeywordOverview keywordsResponse={Array.isArray(keywordsResponse) ? keywordsResponse : []} />
      </Accordion>
      <Accordion
        title="Top Performing Ads"
        className="block sm:hidden dark:bg-[#222732] px-2 py-2 mt-0"
        icon="BingAnalyticsTab"
      >
        <BingTopPerformingAd topAdsResponse={Array.isArray(topAdsResponse) ? topAdsResponse : []} />
      </Accordion>

      <Accordion
        title="Quality Score Overview"
        className="block sm:hidden dark:bg-[#222732] px-2 py-2 mt-0"
        icon="BingAnalyticsTab"
      >
        <BingQualityScoreOverview keywordsResponse={Array.isArray(keywordsResponse) ? keywordsResponse : []} />
      </Accordion>

      <Accordion
        title="Device Overview"
        className="block sm:hidden dark:bg-[#222732] px-2 py-2 mt-0"
        icon="BingAnalyticsTab"
      >
        <BingDeviceOverview />
      </Accordion>

      {/* Desktop view */}

      <Card
        id="microsoftOverview"
        className="hidden sm:block dark:bg-[#222732] px-2 sm:px-6 mt-0 sm:mt-2"
      >
        <MicrosoftAdsOverview />
      </Card>
      <Card
        id="microsoftCampaign"
        className="hidden sm:block dark:bg-[#222732] px-2 sm:px-6 mt-0 sm:mt-8"
      >
        <BingCampaignOverview filteredCampaigns={Array.isArray(filteredCampaigns) ? filteredCampaigns : []} />
      </Card>
      <Card
        id="microsoftAdGroup"
        className="hidden sm:block dark:bg-[#222732] px-2 sm:px-6 mt-0 sm:mt-8"
      >
        <BingAdGroupOverview adGroupsResponse={Array.isArray(adGroupsResponse) ? adGroupsResponse : []} />
      </Card>
      <Card
        id="microsoftKeyword"
        className="hidden sm:block dark:bg-[#222732] px-2 sm:px-6 mt-0 sm:mt-8"
      >
        <BingKeywordOverview keywordsResponse={Array.isArray(keywordsResponse) ? keywordsResponse : []} />
      </Card>
      <Card
        id="microsoftTopPerformingAd"
        className="hidden sm:block dark:bg-[#222732] px-2 sm:px-6 mt-0 sm:mt-8"
      >
        <BingTopPerformingAd topAdsResponse={Array.isArray(topAdsResponse) ? topAdsResponse : []} />
      </Card>
      <Card
        id="microsoftQualityScore"
        className="hidden sm:block dark:bg-[#222732] px-2 sm:px-6 mt-0 sm:mt-8"
      >
        <BingQualityScoreOverview keywordsResponse={Array.isArray(keywordsResponse) ? keywordsResponse : []} />
      </Card>
      {/* <Card
        id="impressionShare"
        className="hidden sm:block dark:bg-[#222732] px-2 sm:px-6 mt-0 sm:mt-8"
      >
        <ImpressionShareReportData campaignResponse={campaignResponse} />
      </Card> */}
      <Card
        id="microsoftDevices"
        className="hidden sm:block dark:bg-[#222732] px-2 sm:px-6 mt-0 sm:mt-8"
      >
        <BingDeviceOverview />
      </Card>
    </div>
  );
};

export default AnalyticsBingAds;
