import React, { useEffect, useState, useRef } from 'react';
import Icon from '../../../assets/icons/SvgComponent';
import Content from './Content';
import Comments from './comments';
import Settings from './settings';
import History from './History';
import Metric from './Metric';
import useAuthStore from '../../../store/useAuthStore';
import useReportStore from '../../../store/useReportStore';
import useClientStore from '../../../store/useClientStore';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import moment from 'moment';
import ReactSelect from '../../ReactSelect';
import DropdownHS from '../../DropdownHS';
import { getHealthStatus, getHealthStatusRole } from '../../../utils/report';
import { motion } from 'framer-motion';
import api from '../../../api';
import Alert from '../../Alert';
import Tooltip from '../../HelpTooltip';
import { ToastContainer, toast, Flip } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import CustomModal from '../../CustomModal';
import PendingChangesModal from './pendingChangesModal';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination } from 'swiper/modules';
import SwiperButtonNext from '../../SwiperButton/swiper';
import { useThemeStore } from '../../../store/useThemeStore';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

type Props = {
  onClose: () => void;
  clientReport?: any;
  handleFetchReports: () => void;
  pendingChangesModal?: boolean;
  setPendingChanges?: React.Dispatch<React.SetStateAction<boolean>>;
  setPendingChangesModal?: React.Dispatch<React.SetStateAction<boolean>>;
};
const Report: React.FC<Props> = ({
  onClose,
  clientReport,
  handleFetchReports,
  pendingChangesModal,
  setPendingChangesModal,
  setPendingChanges,
}) => {
  const [activeBtn, setActiveBtn] = useState<string>('Report');
  const [reportForm, setReportForm] = useState(clientReport);
  const [updateMsg, setUpdateMsg] = useState<boolean | string>(false);
  const [showLastMonth, setShowLastMonth] = useState(false);
  const [isGenerating, setIsGenerating] = useState(false);
  const dashthisURL =
    reportForm?.report?.dashthis_url ||
    clientReport?.[`${clientReport?.report_type}_report_config`]?.dashthis_url;
  const rankrangerURL =
    reportForm?.report?.rank_ranger_url ||
    clientReport?.[`${clientReport?.report_type}_report_config`]
      ?.rank_ranger_url;
  const is_inPregress = clientReport?.status === 'in_progress';
  const isChanged = JSON.stringify(clientReport) !== JSON.stringify(reportForm);
  const isSeo = clientReport?.report_type?.includes('seo');
  const [disableSave, setDisableSave] = useState(false);
  const { updateClient } = useClientStore((state) => state);
  const { user } = useAuthStore((state) => state);
  const {
    updateReport,
    createReport,
    fetchComments,
    fetchHealthHistory,
    report,
    fetchReportSummary,
    loading,
  } = useReportStore((state) => state);
  const { reportDate, cycle, type, all, select } = useReportStore(
    (state) => state
  ).filters;
  const { mode } = useThemeStore((state) => state);

  useEffect(() => {
    let storedReportForm = localStorage.getItem('reportForm');
    if (!storedReportForm) {
      localStorage.setItem('reportForm', JSON.stringify(reportForm));
      storedReportForm = reportForm;
    } else {
      storedReportForm = JSON.parse(storedReportForm);
    }

    const changes =
      JSON.stringify(storedReportForm) !== JSON.stringify(reportForm);
    setPendingChanges(changes);
  }, [reportForm]);

  const preMonthReport = report?.data && report?.data[0];
  const clientName = preMonthReport ? preMonthReport?.name : clientReport.name;

  const prevDate = preMonthReport
    ? moment(preMonthReport?.report_date).format('MMMM YYYY')
    : moment(clientReport?.report_date)
        .subtract(1, 'month')
        .format('MMMM YYYY');

  const getSummaries = () => {
    const filters = {
      date: reportDate,
      type: type.id,
      cycle: cycle.id,
      all_users: all.id,
      missing_field: select.id,
      report_basis: 'monthly',
    };
    fetchReportSummary(filters);
  };

  const updateClientDetails = () => {
    const isLinkChanged =
      reportForm?.report.dashthis_url !== clientReport?.report?.dashthis_url ||
      reportForm?.report.rank_ranger_url !==
        clientReport?.report?.rank_ranger_url ||
      reportForm?.report.strategy !== clientReport?.report?.strategy ||
      reportForm?.report.outreach_url !== clientReport?.report?.outreach_url;
    if (isLinkChanged) {
      let data;
      if (reportForm.report_type.includes('ppc')) {
        data = {
          ppc_google_report_config: {
            dashthis_url: reportForm?.report.dashthis_url,
          },
        };
      } else if (reportForm.report_type.includes('seo')) {
        data = {
          seo_google_report_config: {
            dashthis_url: reportForm?.report.dashthis_url,
            outreach_url: reportForm?.report.outreach_url,
            rank_ranger_url: reportForm?.report.rank_ranger_url,
            strategy: reportForm?.report.strategy,
          },
        };
      } else if (reportForm.report_type.includes('meta')) {
        data = {
          social_meta_report_config: {
            dashthis_url: reportForm?.report.dashthis_url,
          },
        };
      }
      updateClient(reportForm.client_id, data);
    }
  };

  const handleSubmit = () => {
    if (clientReport.status === 'draft') {
      let data = reportForm;
      data = {
        ...data,
        report: {
          ...data.report,
          ...reportForm[`${reportForm?.report_type}_report_config`],
        },
      };
      setDisableSave(true);
      createReport(data)
        .then((response: Response) => {
          getSummaries();
          handleFetchReports();
          toast.success(
            <Alert
              icon=""
              title={`REPORT CREATED`}
              message={`Report has been created successfully`}
            />,
            {
              position: 'top-right',
              autoClose: 2000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            }
          );
          setPendingChangesModal(false);
          setPendingChanges(false);
          setTimeout(() => {
            setUpdateMsg(false);
            // onClose();
          }, 1500);
        })
        .catch((e: unknown) => setUpdateMsg(String(e)));
    } else {
      updateReport(reportForm.id, reportForm)
        .then((response: Response) => {
          updateClientDetails();
          getSummaries();
          handleFetchReports();
          toast.success(
            <Alert
              icon=""
              title={`REPORT UPDATE`}
              message={`Report has been updated successfully`}
            />,
            {
              position: 'top-right',
              autoClose: 2000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            }
          );
          setPendingChangesModal(false);
          setPendingChanges(false);
          setTimeout(() => {
            setUpdateMsg(false);
          }, 2000);
        })
        .catch((e: unknown) =>
          toast.error(
            <Alert icon="" title={`ERROR REPORT`} message={`${String(e)}`} />,
            {
              position: 'top-right',
              autoClose: 2000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            }
          )
        );
    }
  };

  const tabs = [
    {
      label: 'Report',
      content: (
        <Content
          reportForm={reportForm}
          setReportForm={setReportForm}
          loading={loading}
        />
      ),
    },
    { label: 'Metrics', content: <Metric clientReport={clientReport} /> },
    { label: 'Health History', content: <History /> },
    { label: 'Change History', content: <Comments /> },
    {
      label: 'Settings',
      content: (
        <Settings
          reportForm={reportForm}
          setReportForm={setReportForm}
          clientReport={clientReport}
        />
      ),
    },
  ];

  const status = reportForm?.status;
  let color;
  let bg;
  if (status === 'review' || status === 'draft') {
    color = '#CF5C00';
    bg = '#FFF2E8';
  } else if (status === 'rejected' || status === 'canceled') {
    color = '#8F2929';
    bg = '#FCDADA';
  } else if (status === 'validated' || status === 'sent') {
    color = '#00375F';
    bg = '#E6EFF5';
  } else if (status === 'in_progress') {
    color = '#CCA200';
    bg = '#FFFAE6';
  } else {
    color = '#8F2929';
    bg = '#FCDADA';
  }

  //health status
  useEffect(() => {
    const filters = {
      client_id: reportForm.client_id,
      report_type: reportForm.report_type,
    };
    setHealthStatus(getHealthStatus(reportForm));
    fetchComments({
      ...filters,
      date: moment(clientReport?.report_date).format('MM-YYYY'),
    });
    fetchHealthHistory(filters);
  }, [reportForm.report_type]);

  const defaultHealthStatus = [
    { val: 'gray', role: 'am' },
    { val: 'gray', role: 'const' },
  ];
  const healthStatusOptions = [
    { label: 'Red', value: 'red' },
    { label: 'Amber', value: 'amber' },
    { label: 'Green', value: 'green' },
  ];

  const [healthStatus, setHealthStatus] = useState(defaultHealthStatus);

  const handleStatusChange = (val: any, key: any, index: number) => {
    if (key === 'health_status') {
      const name = user.first_name + ' ' + user.last_name;
      const role = getHealthStatusRole(user.role.id);
      const newStatus = {
        val: val.value,
        role: role,
        user_id: user.id,
        name: name,
      };
      const updatedStatus = [...healthStatus];
      updatedStatus[index] = newStatus;
      const updatedReportForm = {
        ...reportForm,
        [key]: updatedStatus?.slice()?.filter((obj) => 'user_id' in obj),
      };
      setHealthStatus(updatedStatus);
      if (
        updatedReportForm &&
        updatedReportForm.health_status &&
        index >= 0 &&
        index < updatedReportForm.health_status.length
      ) {
        // Update 'first_name' property at the specified index
        updatedReportForm.health_status[index].first_name = user.first_name;
        updatedReportForm.health_status[index].last_name = user.last_name;
      }
      setReportForm(updatedReportForm);
    } else {
      setReportForm({
        ...reportForm,
        [key]: val.value,
      });
    }
  };

  const handleFiltersChange = (date: any) => {
    setReportForm({
      ...reportForm,
      report_date: date,
    });
  };

  // use ref to restore the scroll bar at top
  const scrollRef = useRef<any>(null);
  useEffect(() => {
    if (scrollRef.current) {
      scrollRef.current.scrollTop = 0;
    }
  }, [activeBtn]);
  const checkStatus = (i: number) => {
    if (
      i === 0 &&
      ['am', 'admin', 'ad', 'gad', 'omgstf', 'exec', 'fo'].includes(
        user.gurulytics_role.id
      )
    ) {
      return false;
    }
    if (
      i === 1 &&
      ['seoman', 'adsman', 'socman', 'seocon', 'ppccon', 'soccon'].includes(
        user.gurulytics_role.id
      )
    ) {
      return false;
    }
    return true;
  };

  const generateAPIContent = async () => {
    setIsGenerating(true);
    const response = await api.Report.aiGenerate({ id: reportForm.id });
    if (response[0]?.error) {
      setIsGenerating(false);
      toast.warning(
        <Alert
          icon=""
          title={`ERROR RESPONSE`}
          message={`${response[0]?.error}`}
        />,
        {
          position: 'top-right',
          autoClose: 2000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        }
      );
      return;
    } else {
      handleFetchReports();
      setReportForm({
        ...reportForm,
        report: response?.report,
        raw_data: response?.raw_data,
      });
      toast.success(
        <Alert
          icon=""
          title={`GENERATED`}
          message={`Report has been generated successfully`}
        />,
        {
          position: 'top-right',
          autoClose: 2000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        }
      );
      setTimeout(() => {
        setUpdateMsg(false);
        setIsGenerating(false);
        // onClose();
      }, 2000);
    }
  };

  return (
    <>
      <motion.div
        initial={{ x: '100%' }}
        exit={{ x: '100%' }}
        animate={{ x: 0 }}
        transition={{ duration: 0.3, ease: 'easeInOut' }}
        style={{ display: 'flex', justifyContent: 'flex-end', height: '100%' }}
      >
        {showLastMonth && (
          <motion.div
            className="w-1/2 h-full flex flex-col relative bg-[#EAEAEA] shadow-lg overflow-y-auto dark:bg-gray-800"
            onClick={(e) => {
              e.stopPropagation();
            }}
            initial={{ x: '100%' }}
            exit={{ x: '100%' }}
            animate={{ x: 0 }}
            transition={{ duration: 0.3, ease: 'easeInOut' }}
          >
            <div className="sticky top-0 z-[3] bg-[#EAEAEA] pb-[50px] dark:bg-gray-800">
              <div className="flex flex-col gap-2 pt-6 pl-8 ">
                <h1 className="text-2xl font-semibold dark:text-white">
                  {clientName}
                </h1>
                <p>{prevDate}</p>
              </div>
              <div
                style={{
                  display: 'flex',
                  marginLeft: 17,
                  justifyContent: 'space-between',
                  paddingRight: 30,
                }}
              >
                <div style={{ display: 'flex' }}>
                  {preMonthReport?.health_status?.map(
                    (item: any, i: number) => {
                      return (
                        <div key={i}>
                          <DropdownHS
                            value={item}
                            index={i}
                            onSelect={(value: any) =>
                              handleStatusChange(value, 'health_status', i)
                            }
                            items={healthStatusOptions}
                            disabled={true}
                            styles={{ width: 110, marginLeft: 10 }}
                          />
                        </div>
                      );
                    }
                  )}
                </div>
              </div>
            </div>
            <Content lastMonth={showLastMonth} reportForm={preMonthReport} />
          </motion.div>
        )}
        <div
          onClick={(e) => {
            e.stopPropagation();
          }}
          style={{
            display: 'flex',
            position: 'relative',
            borderRight: '5px solid #A585EC',
            zIndex: 2,
          }}
        >
          {!showLastMonth ? (
            <div
              onClick={() => setShowLastMonth(true)}
              style={{
                right: 0,
                top: '35%',
                display: 'flex',
                background: '#A585EC',
                borderRadius: 5,
                width: 'max-content',
                padding: '0px 0px',
                transform: 'rotate(270deg)',
                transformOrigin: 'right bottom',
                position: 'absolute',
                cursor: 'pointer',
                zIndex: 2,
              }}
            >
              <span
                style={{
                  padding: '2px 5px',
                  color: '#fff',
                  fontSize: 12,
                  fontWeight: '500',
                }}
              >
                Last Month’s Report
              </span>
              <Icon name="arrowup" color="#fff" />
            </div>
          ) : (
            <div
              onClick={() => setShowLastMonth(false)}
              style={{
                right: 0,
                top: '40%',
                display: 'flex',
                background: '#A585EC',
                borderRadius: 5,
                width: 40,
                padding: '0px 0px',
                justifyContent: 'center',
                transform: 'rotate(90deg)',
                transformOrigin: 'right bottom',
                position: 'absolute',
                cursor: 'pointer',
                zIndex: 2,
              }}
            >
              <Icon name="arrowup" color="#fff" />
            </div>
          )}
        </div>
        <div
          className="flex flex-col relative w-screen sm:w-1/2 h-full shadow-sm overflow-y-auto bg-white px-2 sm:px-8 dark:bg-gray-900"
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          {isGenerating && (
            <div className="absolute left-1/2 top-20 z-10">
              <Icon name="loading" />
            </div>
          )}
          {updateMsg && (
            <div className="flex items-center justify-center p-2 w-1/2  ">
              <span className="text-red-800">{updateMsg}</span>
            </div>
          )}
          <div
            className="absolute right-6 top-6 z-10 cursor-pointer"
            onClick={onClose}
          >
            <Icon name="close" size={20} />
          </div>
          <div className="sticky top-0 ">
            <div className="flex flex-col gap-y-4 w-full bg-white py-4 dark:bg-gray-900">
              <div className="sm:flex items-center gap-x-2">
                <h1 className="text-2xl max-w-full truncate font-semibold dark:text-white">
                  {clientReport.name}
                </h1>
                <DatePicker
                  selected={moment(reportForm?.report_date).toDate()}
                  onChange={(date) => handleFiltersChange(date)}
                  dateFormat="MMMM yyyy"
                  disabled={!is_inPregress}
                  showMonthYearPicker
                  className="flex items-center w-[130px] text-center justify-center rounded-lg border p-[7px] border-[#808EA2] cursor-pointer dark:bg-gray-700 dark:text-white mr-4 sm:mr-0"
                />
                <span
                  style={{
                    padding: '2px 8px',
                    borderRadius: 16,
                    backgroundColor: `${bg}`,
                    color: `${color}`,
                    border: `1px solid ${bg}`,
                    fontSize: 12,
                  }}
                >
                  {reportForm?.status
                    .replace(/_/g, ' ')
                    .replace(/\b\w/g, (char: string) => char.toUpperCase())}
                </span>
              </div>
              <div className="flex flex-wrap sm:flex-nowrap items-center justify-between">
                <div className="flex  items-center gap-x-3 w-full sm:w-1/2 mb-4 sm:mb-0">
                  <div className="flex flex-col">
                    <span className="text-sm font-semibold dark:text-white">
                      {clientReport?.report_type
                        ?.replace('_', ' ')
                        .toUpperCase()}
                    </span>
                    {isSeo && (
                      <span className="w-11 text-xs text-[#636466]">
                        {clientReport.cycle}
                      </span>
                    )}
                  </div>
                  <Icon name="line03" />
                  <Tooltip
                    content={!dashthisURL ? 'No Dashboard Link' : dashthisURL}
                    position="top"
                  >
                    <a
                      href={dashthisURL}
                      target="blank"
                      className="text-[#005C9F] font-semibold text-sm"
                    >
                      DASHBOARD
                    </a>
                  </Tooltip>
                  <Icon name="line03" />
                  <Tooltip
                    content={
                      !rankrangerURL ? 'No Rank Ranger Link' : rankrangerURL
                    }
                    position="top"
                  >
                    <a
                      href={rankrangerURL}
                      target="blank"
                      className="text-[#005C9F] font-semibold text-sm"
                    >
                      RANKRANGER
                    </a>
                  </Tooltip>
                </div>
                <div className="flex items-center gap-x-3">
                  {healthStatus?.map((item, i) => {
                    return (
                      <div className="z-[999]" key={i}>
                        <ReactSelect
                          options={healthStatusOptions}
                          value={{ label: item.val, value: item.val }}
                          isDisabled={checkStatus(i)}
                          handleOnChange={(val) =>
                            handleStatusChange(val, 'health_status', i)
                          }
                        />
                      </div>
                    );
                  })}
                </div>
              </div>

              <div className="hidden w-full sm:flex bg-[#F3F7FA] py-2 px-4 gap-x-6 rounded-lg dark:border border-gray-600 dark:bg-gray-700">
                {tabs?.map(
                  (
                    value: { label: string; content: JSX.Element },
                    index: number
                  ) => {
                    return (
                      <div key={index}>
                        <button
                          className={`text-xs sm:text-sm ${
                            activeBtn === value.label
                              ? 'text-[#001C44] font-semibold dark:text-white'
                              : ' text-[#7C7E7F] '
                          } `}
                          onClick={() => setActiveBtn(value.label)}
                        >
                          {value.label}
                        </button>
                      </div>
                    );
                  }
                )}
              </div>
              <div className="relative block w-full sm:hidden">
                <Swiper
                  className="h-full flex justify-between mySwiper"
                  modules={[Pagination]}
                  spaceBetween={2}
                  slidesPerView={3}
                >
                  <div>
                    <SwiperButtonNext next={true}>
                      <Icon
                        name="ChevronRight"
                        size={30}
                        color={mode === 'dark' ? '#FFF' : '#000'}
                      />
                    </SwiperButtonNext>
                  </div>
                  {tabs?.map(
                    (
                      value: { label: string; content: JSX.Element },
                      index: number
                    ) => (
                      <SwiperSlide
                        key={index}
                        className={`font-inter rounded-md ${
                          activeBtn === value.label
                            ? ` text-black  dark:text-white font-semibold`
                            : ' text-[#7C7E7F] dark:text-gray-500 '
                        }`}
                        onClick={() => setActiveBtn(value.label)}
                      >
                        <div className="w-full text-center text-sm">
                          {value.label}
                        </div>
                      </SwiperSlide>
                    )
                  )}
                  <div>
                    <SwiperButtonNext prev={true}>
                      <Icon
                        name="ChevronLeft"
                        size={30}
                        color={mode === 'dark' ? '#FFF' : '#000'}
                      />
                    </SwiperButtonNext>
                  </div>
                </Swiper>
              </div>
              <div className="w-full h-0.5 bg-[#E6EBF0]" />
            </div>
          </div>
          <div
            ref={scrollRef}
            className="w-full  overflow-y-auto dark:bg-gray-900  scrollbar-thin scrollbar-thumb-gray-300 scrollbar-track-gray-100 scrollbar-rounded-8 flex flex-col items-center mb-[100px]"
          >
            {tabs.map((tab, index) =>
              activeBtn === tab.label ? (
                <div key={index} className="w-full h-[100%]">
                  {tab.content}
                </div>
              ) : null
            )}
          </div>
        </div>
        <div
          className={`flex gap-2 sm:gap-8 bg-white ${isSeo ? '' : 'justify-center'} fixed bottom-0 px-4 w-full sm:w-1/2 py-6  dark:bg-gray-800 z-[85]`}
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          {isSeo && (
            <button
              className={`flex items-center justify-center gap-1  h-[42px] w-full text-white bg-[#005C9F] border border-[#005C9F] rounded-lg dark:bg-[#005C9F] ${'cursor-pointer'}`}
              onClick={generateAPIContent}
            >
              Generate
              <Icon name="magic" />
            </button>
          )}
          <button
            className={` h-[42px] border border-[#808EA2] ${isSeo ? 'w-full bg-white text-[#808EA2]' : 'w-[96%] bg-[#005C9F] border-[#005C9F] text-white'}  rounded-lg dark:bg-gray-700 dark:text-white ${'cursor-pointer'}`}
            title={isChanged && !disableSave ? '' : 'No changes to save!'}
            onClick={() => isChanged && !disableSave && handleSubmit()}
          >
            Save
          </button>
        </div>
        <ToastContainer
          position="top-right"
          autoClose={30000}
          hideProgressBar
          newestOnTop={false}
          transition={Flip}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="light"
        />
      </motion.div>
      <CustomModal
        open={pendingChangesModal}
        onClose={() => {
          setPendingChangesModal(false);
        }}
      >
        <PendingChangesModal
          onClose={() => {
            setPendingChangesModal(false);
            setPendingChanges(false);
            localStorage.removeItem('reportForm');
          }}
          onSave={() => handleSubmit()}
        />
      </CustomModal>
    </>
  );
};

export default Report;
