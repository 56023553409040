import { useMemo } from "react";
import { useThemeStore } from "../../../store/useThemeStore";
import Icon from '../../../assets/icons/SvgComponent';
import { formatNumberWithCommas } from "../../../utils/common";
import AnalyticsTable from "../../AnalyticsSections/AnalyticsTable";


interface IProps {
  data?: any;
  isLoading?: boolean;
}

const OrgRankingPosition = ({
  data,
  isLoading
}: IProps) => {
  
  const { mode } = useThemeStore((state) => state);
  const orgSearchPositionSource = data ?? [];

  // console.log(orgSearchPositions);

  const orgSearchPositionsData = useMemo(() => {
    const tableHeader = [
      {
        id: 'Keyword',
        header: (props) => {
          const headerId = props?.table?.options?.state?.sorting[0]?.id;
          const sortDirection = props?.table?.options?.state?.sorting[0]?.desc;
          if (headerId === 'Keyword' && sortDirection === false) {
            return (
              <div className="flex justify-start items-center gap-2 font-inter w-[150px] sm:w-[15vw] h-fit pl-2 sm:pl-4">
                <div>Keyword</div>
                <Icon name="ChevronUp" color="#808DA1" size={20} />
              </div>
            );
          }

          if (headerId === 'Keyword' && sortDirection === true) {
            return (
              <div className="flex justify-start items-center gap-2 font-inter w-[150px] sm:w-[15vw] h-fit pl-2 sm:pl-4">
                <div>Keyword</div>
                <Icon name="ChevronDown" color="#808DA1" size={20} />
              </div>
            );
          }

          if (headerId !== 'Keyword' || headerId === 'Keyword') {
            return (
              <div className="flex justify-start items-center gap-2 font-inter w-[150px] sm:w-[15vw] h-fit pl-2 sm:pl-4">
                <div>Keyword</div>
                <Icon name="Sort" />
              </div>
            );
          }
        },
        accessorKey: 'Keyword',
        cell: (props: any) => (
          <div className="flex justify-start items-center font-inter w-[150px] sm:w-[15vw] pl-2 sm:pl-4 h-fit">
            <Icon
              name="ChevronRight"
              size={18}
              color={mode === 'dark' ? '#fff' : ''}
            />
            <div className="truncate w-[150px] sm:max-w-[12vw] overflow-hidden text-[#0029FF] dark:text-white">
              {props.getValue()}
            </div>
          </div>
        ),
      },
      {
        id: 'Intent',
        header: (props) => {
          const headerId = props?.table?.options?.state?.sorting[0]?.id;
          const sortDirection = props?.table?.options?.state?.sorting[0]?.desc;
          if (headerId === 'Intent' && sortDirection === false) {
            return (
              <div className="flex justify-center items-center gap-2 font-inter w-[80px] sm:w-[8vw] h-fit">
                <div>Intent</div>
                <Icon name="ChevronUp" color="#808DA1" size={20} />
              </div>
            );
          }

          if (headerId === 'Intent' && sortDirection === true) {
            return (
              <div className="flex justify-center items-center gap-2 font-inter w-[80px] sm:w-[8vw] h-fit">
                <div>Intent</div>
                <Icon name="ChevronDown" color="#808DA1" size={20} />
              </div>
            );
          }

          if (headerId !== 'Intent' || headerId === 'Intent') {
            return (
              <div className="flex justify-center items-center gap-2 font-inter w-[80px] sm:w-[8vw] h-fit">
                <div>Intent</div>
                <Icon name="Sort" />
              </div>
            );
          }
        },
        accessorKey: 'Intents',
        cell: (props: any) => (
          <div className="flex justify-center font-inter w-[80px] sm:w-[8vw] h-fit">
            <div
              className={
                props.getValue() === 'l'
                  ? 'bg-[#E2FAF1] rounded-full px-1'
                  : 'bg-[#FFF2E8] rounded-full p-1 px-2 text-[#FF821D]'
              }
            >
              {props.getValue()}
            </div>
          </div>
        ),
      },
      {
        id: 'Position',
        header: (props) => {
          const headerId = props?.table?.options?.state?.sorting[0]?.id;
          const sortDirection = props?.table?.options?.state?.sorting[0]?.desc;
          if (headerId === 'Position' && sortDirection === false) {
            return (
              <div className="flex justify-center items-center gap-2 font-inter w-[120px] sm:w-[8vw] h-fit">
                <div>Position</div>
                <Icon name="ChevronUp" color="#808DA1" size={20} />
              </div>
            );
          }

          if (headerId === 'Position' && sortDirection === true) {
            return (
              <div className="flex justify-center items-center gap-2 font-inter w-[120px] sm:w-[8vw] h-fit">
                <div>Position</div>
                <Icon name="ChevronDown" color="#808DA1" size={20} />
              </div>
            );
          }

          if (headerId !== 'Position' || headerId === 'Position') {
            return (
              <div className="flex justify-center items-center gap-2 font-inter w-[120px] sm:w-[8vw] h-fit">
                <div>Position</div>
                <Icon name="Sort" size={20} />
              </div>
            );
          }
        },
        accessorKey: 'Position',
        cell: (props: any) => (
          <div className="flex justify-center items-center gap-2 font-inter w-[120px] sm:w-[8vw] h-fit">
            <Icon name="Image" size={18} color="#808DA1" />
            {formatNumberWithCommas(props.getValue())}
          </div>
        ),
      },
      {
        id: 'CPC',
        header: (props) => {
          const headerId = props?.table?.options?.state?.sorting[0]?.id;
          const sortDirection = props?.table?.options?.state?.sorting[0]?.desc;
          if (headerId === 'CPC' && sortDirection === false) {
            return (
              <div className="flex justify-center items-center gap-2 font-inter w-[120px] sm:w-[8vw] h-fit">
                <div>CPC</div>
                <Icon name="ChevronUp" color="#808DA1" size={20} />
              </div>
            );
          }

          if (headerId === 'CPC' && sortDirection === true) {
            return (
              <div className="flex justify-center items-center gap-2 font-inter w-[120px] sm:w-[8vw] h-fit">
                <div>CPC</div>
                <Icon name="ChevronDown" color="#808DA1" size={20} />
              </div>
            );
          }

          if (headerId !== 'CPC' || headerId === 'CPC') {
            return (
              <div className="flex justify-center items-center gap-2 font-inter w-[120px] sm:w-[8vw] h-fit">
                <div>CPC</div>
                <Icon name="Sort" />
              </div>
            );
          }
        },
        accessorKey: 'CPC',
        cell: (props: any) => (
          <div className="flex justify-center font-inter w-[120px] sm:w-[8vw] h-fit">
            {formatNumberWithCommas(props.getValue())}
          </div>
        ),
      },
      {
        id: 'Competition',
        header: (props) => {
          const headerId = props?.table?.options?.state?.sorting[0]?.id;
          const sortDirection = props?.table?.options?.state?.sorting[0]?.desc;
          if (headerId === 'Competition' && sortDirection === false) {
            return (
              <div className="flex justify-center items-center gap-2 font-inter w-[120px] sm:w-[8vw] h-fit">
                <div>Competition</div>
                <Icon name="ChevronUp" color="#808DA1" size={20} />
              </div>
            );
          }

          if (headerId === 'Competition' && sortDirection === true) {
            return (
              <div className="flex justify-center items-center gap-2 font-inter w-[120px] sm:w-[8vw] h-fit">
                <div>Competition</div>
                <Icon name="ChevronDown" color="#808DA1" size={20} />
              </div>
            );
          }

          if (headerId !== 'Competition' || headerId === 'Competition') {
            return (
              <div className="flex justify-center items-center gap-2 font-inter w-[120px] sm:w-[8vw] h-fit">
                <div>Competition</div>
                <Icon name="Sort" />
              </div>
            );
          }
        },
        accessorKey: 'Competition',
        cell: (props: any) => (
          <div className="flex justify-center font-inter w-[120px] sm:w-[8vw] h-fit">
            {formatNumberWithCommas(props.getValue())}
          </div>
        ),
      },

      {
        id: 'KD',
        header: (props) => {
          const headerId = props?.table?.options?.state?.sorting[0]?.id;
          const sortDirection = props?.table?.options?.state?.sorting[0]?.desc;
          if (headerId === 'KD' && sortDirection === false) {
            return (
              <div className="flex justify-center items-center gap-2 font-inter w-[120px] sm:w-[8vw] h-fit">
                <div>KD %</div>
                <Icon name="ChevronUp" color="#808DA1" size={20} />
              </div>
            );
          }

          if (headerId === 'KD' && sortDirection === true) {
            return (
              <div className="flex justify-center items-center gap-2 font-inter w-[120px] sm:w-[8vw] h-fit">
                <div>KD %</div>
                <Icon name="ChevronDown" color="#808DA1" size={20} />
              </div>
            );
          }

          if (headerId !== 'KD' || headerId === 'KD') {
            return (
              <div className="flex justify-center items-center gap-2 font-inter w-[120px] sm:w-[8vw] h-fit">
                <div>KD %</div>
                <Icon name="Sort" />
              </div>
            );
          }
        },
        accessorKey: 'Keyword Difficulty',
        cell: (props: any) => (
          <div className="flex justify-center font-inter w-[120px] sm:w-[8vw] h-fit">
            {formatNumberWithCommas(props.getValue())}
          </div>
        ),
      },

      {
        id: 'URL',
        header: (props) => {
          const headerId = props?.table?.options?.state?.sorting[0]?.id;
          const sortDirection = props?.table?.options?.state?.sorting[0]?.desc;
          if (headerId === 'URL' && sortDirection === false) {
            return (
              <div className="flex justify-start items-center gap-2 font-inter w-[120px] sm:w-[8vw] h-fit">
                <div>URL</div>
                <Icon name="ChevronUp" color="#808DA1" size={20} />
              </div>
            );
          }

          if (headerId === 'URL' && sortDirection === true) {
            return (
              <div className="flex justify-start items-center gap-2 font-inter w-[120px] sm:w-[8vw] h-fit">
                <div>URL</div>
                <Icon name="ChevronDown" color="#808DA1" size={20} />
              </div>
            );
          }

          if (headerId !== 'URL' || headerId === 'URL') {
            return (
              <div className="flex justify-start items-center gap-2 font-inter w-[120px] sm:w-[8vw] h-fit">
                <div>URL</div>
                <Icon name="Sort" />
              </div>
            );
          }
        },
        accessorKey: 'Url',
        cell: (props: any) => (
          <div className="flex justify-start font-inter w-[120px] sm:w-[8vw] h-fit text-[#0029FF] dark:text-white">
            <div className="w-[120px] sm:max-w-[7vw] truncate">
              {props.getValue()}
            </div>
          </div>
        ),
      },
      {
        id: 'Search Volume',
        header: (props) => {
          const headerId = props?.table?.options?.state?.sorting[0]?.id;
          const sortDirection = props?.table?.options?.state?.sorting[0]?.desc;
          if (headerId === 'Search Volume' && sortDirection === false) {
            return (
              <div className="flex justify-center items-center gap-2 font-inter w-[150px] sm:w-[10vw] h-fit">
                <div>Search Volume</div>
                <Icon name="ChevronUp" color="#808DA1" size={20} />
              </div>
            );
          }

          if (headerId === 'Search Volume' && sortDirection === true) {
            return (
              <div className="flex justify-center items-center gap-2 font-inter w-[150px] sm:w-[10vw] h-fit">
                <div>Search Volume</div>
                <Icon name="ChevronDown" color="#808DA1" size={20} />
              </div>
            );
          }

          if (headerId !== 'Search Volume' || headerId === 'Search Volume') {
            return (
              <div className="flex justify-center items-center gap-2 font-inter w-[150px] sm:w-[10vw] h-fit">
                <div>Search Volume</div>
                <Icon name="Sort" />
              </div>
            );
          }
        },
        accessorKey: 'Search Volume',
        cell: (props: any) => (
          <div className="flex justify-center font-inter w-[150px] sm:w-[10vw] h-fit">
            <div className="w-[150px] text-center  sm:max-w-[10vw] truncate">
              {props.getValue()}
            </div>
          </div>
        ),
      },

      {
        id: 'Traffic',
        header: (props) => {
          const headerId = props?.table?.options?.state?.sorting[0]?.id;
          const sortDirection = props?.table?.options?.state?.sorting[0]?.desc;
          if (headerId === 'Traffic' && sortDirection === false) {
            return (
              <div className="flex justify-center items-center gap-2 font-inter w-[120px] sm:w-[8vw] h-fit">
                <div>Traffic</div>
                <Icon name="ChevronUp" color="#808DA1" size={20} />
              </div>
            );
          }

          if (headerId === 'Traffic' && sortDirection === true) {
            return (
              <div className="flex justify-center items-center gap-2 font-inter w-[120px] sm:w-[8vw] h-fit">
                <div>Traffic</div>
                <Icon name="ChevronDown" color="#808DA1" size={20} />
              </div>
            );
          }

          if (headerId !== 'Traffic' || headerId === 'Traffic') {
            return (
              <div className="flex justify-center items-center gap-2 font-inter w-[120px] sm:w-[8vw] h-fit">
                <div>Traffic</div>
                <Icon name="Sort" />
              </div>
            );
          }
        },
        accessorKey: 'Traffic',
        cell: (props: any) => (
          <div className="flex justify-center font-inter w-[120px] sm:w-[8vw] h-fit">
            <div className="w-[120px] text-center sm:max-w-[7vw] truncate">
              {props.getValue()}
            </div>
          </div>
        ),
      },
      {
        id: 'Traffic (%)',
        header: (props) => {
          const headerId = props?.table?.options?.state?.sorting[0]?.id;
          const sortDirection = props?.table?.options?.state?.sorting[0]?.desc;
          if (headerId === 'Traffic (%)' && sortDirection === false) {
            return (
              <div className="flex justify-center items-center gap-2 font-inter w-[120px] sm:w-[8vw] h-fit">
                <div>Traffic (%)</div>
                <Icon name="ChevronUp" color="#808DA1" size={20} />
              </div>
            );
          }

          if (headerId === 'Traffic (%)' && sortDirection === true) {
            return (
              <div className="flex justify-center items-center gap-2 font-inter w-[120px] sm:w-[8vw] h-fit">
                <div>Traffic (%)</div>
                <Icon name="ChevronDown" color="#808DA1" size={20} />
              </div>
            );
          }

          if (headerId !== 'Traffic (%)' || headerId === 'Traffic (%)') {
            return (
              <div className="flex justify-center items-center gap-2 font-inter w-[120px] sm:w-[8vw] h-fit">
                <div>Traffic (%)</div>
                <Icon name="Sort" />
              </div>
            );
          }
        },
        accessorKey: 'Traffic (%)',
        cell: (props: any) => (
          <div className="flex justify-center font-inter w-[120px] sm:w-[8vw] h-fit">
            <div className="w-[120px] text-center sm:max-w-[7vw] truncate">
              {props.getValue()}%
            </div>
          </div>
        ),
      },
      {
        id: 'Traffic Cost (%)',
        header: (props) => {
          const headerId = props?.table?.options?.state?.sorting[0]?.id;
          const sortDirection = props?.table?.options?.state?.sorting[0]?.desc;
          if (headerId === 'Traffic Cost (%)' && sortDirection === false) {
            return (
              <div className="flex justify-center items-center gap-2 font-inter w-[150px] sm:w-[10vw] h-fit">
                <div>Traffic Cost (%)</div>
                <Icon name="ChevronUp" color="#808DA1" size={20} />
              </div>
            );
          }

          if (headerId === 'Traffic Cost (%)' && sortDirection === true) {
            return (
              <div className="flex justify-center items-center gap-2 font-inter w-[150px] sm:w-[10vw] h-fit">
                <div>Traffic Cost (%)</div>
                <Icon name="ChevronDown" color="#808DA1" size={20} />
              </div>
            );
          }

          if (
            headerId !== 'Traffic Cost (%)' ||
            headerId === 'Traffic Cost (%)'
          ) {
            return (
              <div className="flex justify-center items-center gap-2 font-inter w-[150px] sm:w-[10vw] h-fit">
                <div>Traffic Cost (%)</div>
                <Icon name="Sort" />
              </div>
            );
          }
        },
        accessorKey: 'Traffic Cost (%)',
        cell: (props: any) => (
          <div className="flex justify-center font-inter w-[150px] sm:w-[10vw] h-fit">
            <div className="w-[150px] text-center sm:max-w-[10vw] truncate">
              {props.getValue()}
            </div>
          </div>
        ),
      },
      {
        id: 'Number of Results',
        header: (props) => {
          const headerId = props?.table?.options?.state?.sorting[0]?.id;
          const sortDirection = props?.table?.options?.state?.sorting[0]?.desc;
          if (headerId === 'Number of Results' && sortDirection === false) {
            return (
              <div className="flex justify-center items-center gap-2 font-inter w-[150px] sm:w-[12vw] h-fit">
                <div>Number of Results</div>
                <Icon name="ChevronUp" color="#808DA1" size={20} />
              </div>
            );
          }

          if (headerId === 'Number of Results' && sortDirection === true) {
            return (
              <div className="flex justify-center items-center gap-2 font-inter w-[150px] sm:w-[12vw] h-fit">
                <div>Number of Results</div>
                <Icon name="ChevronDown" color="#808DA1" size={20} />
              </div>
            );
          }

          if (
            headerId !== 'Number of Results' ||
            headerId === 'Number of Results'
          ) {
            return (
              <div className="flex justify-center items-center gap-2 font-inter w-[150px] sm:w-[12vw] h-fit">
                <div>Number of Results</div>
                <Icon name="Sort" />
              </div>
            );
          }
        },
        accessorKey: 'Number of Results',
        cell: (props: any) => (
          <div className="flex justify-center font-inter w-[150px] sm:w-[12vw] h-fit">
            {formatNumberWithCommas(props.getValue())}
          </div>
        ),
      },
      {
        id: 'Position Difference',
        header: (props) => {
          const headerId = props?.table?.options?.state?.sorting[0]?.id;
          const sortDirection = props?.table?.options?.state?.sorting[0]?.desc;
          if (headerId === 'Position Difference' && sortDirection === false) {
            return (
              <div className="flex justify-center items-center gap-2 font-inter w-[160px] sm:w-[12vw] h-fit">
                <div>Position Difference</div>
                <Icon name="ChevronUp" color="#808DA1" size={20} />
              </div>
            );
          }

          if (headerId === 'Position Difference' && sortDirection === true) {
            return (
              <div className="flex justify-center items-center gap-2 font-inter w-[160px] sm:w-[12vw] h-fit">
                <div>Position Difference</div>
                <Icon name="ChevronDown" color="#808DA1" size={20} />
              </div>
            );
          }

          if (
            headerId !== 'Position Difference' ||
            headerId === 'Position Difference'
          ) {
            return (
              <div className="flex justify-center items-center gap-2 font-inter w-[160px] sm:w-[12vw] h-fit">
                <div>Position Difference</div>
                <Icon name="Sort" />
              </div>
            );
          }
        },
        accessorKey: 'Position Difference',
        cell: (props: any) => (
          <div className="flex justify-center font-inter w-[160px] sm:w-[12vw] h-fit">
            {formatNumberWithCommas(props.getValue() ?? 0)}
          </div>
        ),
      },
      {
        id: 'Previous Position',
        header: (props) => {
          const headerId = props?.table?.options?.state?.sorting[0]?.id;
          const sortDirection = props?.table?.options?.state?.sorting[0]?.desc;
          if (headerId === 'Previous Position' && sortDirection === false) {
            return (
              <div className="flex justify-center items-center gap-2 font-inter w-[150px] sm:w-[12vw] h-fit">
                <div>Previous Position</div>
                <Icon name="ChevronUp" color="#808DA1" size={20} />
              </div>
            );
          }

          if (headerId === 'Previous Position' && sortDirection === true) {
            return (
              <div className="flex justify-center items-center gap-2 font-inter w-[150px] sm:w-[12vw] h-fit">
                <div>Previous Position</div>
                <Icon name="ChevronDown" color="#808DA1" size={20} />
              </div>
            );
          }

          if (
            headerId !== 'Previous Position' ||
            headerId === 'Previous Position'
          ) {
            return (
              <div className="flex justify-center items-center gap-2 font-inter w-[150px] sm:w-[12vw] h-fit">
                <div>Previous Position</div>
                <Icon name="Sort" />
              </div>
            );
          }
        },
        accessorKey: 'Previous Position',
        cell: (props: any) => (
          <div className="flex justify-center font-inter w-[150px] sm:w-[12vw] h-fit">
            {formatNumberWithCommas(props.getValue())}
          </div>
        ),
      },
    ];

    const data = orgSearchPositionSource.map((item) => {
      return {
        Keyword: item?.Keyword,
        Intents: item?.Intents,
        Position: Number(item?.Position),
        CPC: Number(item?.CPC),
        Competition: Number(item?.Competition),
        'Keyword Difficulty': Number(item?.['Keyword Difficulty']),
        Url: item?.Url,
        'Search Volume': Number(item?.['Search Volume']),
        Traffic: Number(item?.Traffic),
        'Traffic (%)': Number(item?.['Traffic (%)']),
        'Traffic Cost (%)': Number(item?.['Traffic Cost (%)']),
        'Number of Results': Number(item?.['Number of Results']),
        'Position Difference': Number(item?.['Position Difference']),
        'Previous Position': Number(item?.['Previous Position']),
      };
    });

    return {
      tableHeader,
      data,
    };
  }, [mode, orgSearchPositionSource]);

  return (
    <div className="relative overflow-auto max-w-full">
       <AnalyticsTable
          data={
            orgSearchPositionsData?.data ? orgSearchPositionsData?.data : []
          }
          columns={orgSearchPositionsData?.tableHeader}
          loading={false}
          totalDocs={
            orgSearchPositionsData?.data
              ? orgSearchPositionsData?.data.length
              : 'N/A'
          }
          sortByColumnId="Position"
          pagination={true}
          // error={ga4SeoData?.landingPage?.error}
          // retryMethod={retryFetchGA4Analytics}
        />
    </div>
  )
}

export default OrgRankingPosition